<template>
	<ons-page>
		<v-ons-toolbar>
			<div class="center">
				<p class="text-center m-0 text-capitalize">
					{{ $tc('employee', 2) }}
				</p>
			</div>
			<div class="right">
				<v-ons-toolbar-button
					v-if="$bridge.getName() === 'ANDROID' && $offline.state === 'up'"
					icon="md-refresh"
					@click="$bridge.refresh && $bridge.refresh()"
				/>
			</div>
		</v-ons-toolbar>
		<div class="background bg-white" />
		<v-ons-list class="mb-5">
			<v-ons-list-header
				class="text-capitalize"
			>
				{{ $tc('employee', 3) }}
			</v-ons-list-header>
			<v-ons-list-item
				v-for="(employee, index) in employees"
				:key="index"
				tappable
				@click="selectEmployee(employee)"
			>
				<div class="left">
					<v-ons-icon
						icon="fa-id-badge"
						class="list-item__icon text-muted"
					/>
				</div>
				<div class="center">
					<p class="m-0">
						{{ employee.name }}
					</p>
				</div>
				<div class="right">
					<small class="text-capitalize">
						{{ employee.roles.find(role => role.isAdmin === true) ? $t('full access') : $t('limited access') }}
					</small>
				</div>
			</v-ons-list-item>
		</v-ons-list>
		<v-ons-toast
			class="text-capitalize"
			:visible.sync="showOffline"
			animation="ascend"
		>
			{{ $t('device is offline') }}
			<button
				class="text-capitalize"
				@click="showOffline = false"
			>
				{{ $t('ok') }}
			</button>
		</v-ons-toast>
		<v-ons-button
			modifier="large"
			class="rounded-0 text-capitalize"
			style="position: fixed; bottom: 0"
			@click="isLogoutVisible = true"
		>
			{{ $t('logout') }}
		</v-ons-button>
		<v-ons-alert-dialog
			modifier="rowfooter"
			:title="$t('logoutDialog.title')"
			:visible.sync="isLogoutVisible"
		>
			{{ $t('logoutDialog.text') }}
			<template slot="footer">
				<v-ons-alert-dialog-button
					class="text-capitalize"
					@click="logout()"
				>
					{{ $t('ok') }}
				</v-ons-alert-dialog-button>
				<v-ons-alert-dialog-button
					class="text-capitalize"
					@click="isLogoutVisible = false"
				>
					{{ $tc('cancel', 1) }}
				</v-ons-alert-dialog-button>
			</template>
		</v-ons-alert-dialog>
	</ons-page>
</template>

<script>
	import Passcode from '~/components/passcode'
	import Subscription from '~/components/subscription'

	export default {
		name: 'Employees',
		data() {
			return {
				isLogoutVisible : false,
				showOffline: false 
			}
		},
		computed: {
			employees() {
				return this.$store.state.employees
			},
			pageStack: {
				get() {
					return this.$store.state.pageStack
				},
				set(pageStack) {
					this.$store.commit('setPageStack', pageStack)
				}
			},
			merchant() {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
		},
		mounted() {
			if (this.$nuxt.isOffline) {
				this.showOffline = true 
			}
			if (this.merchant.subscription && this.merchant.subscription.show_modal) {
				this.pageStack.push(Subscription)
			}
		},
		methods: {
			selectEmployee(employee) {
				this.$bridge.setLocalStorage('employee', JSON.stringify(employee))
				this.$store.commit('setState', {
					key: 'employee',
					value: employee,
					save: true
				})
				this.$emit('push', {
					extends: Passcode,
					onsNavigatorProps: {
						type: 'clockin'
					}
				})
			},
			logout(){
				this.isLogoutVisible = false
				this.$store.dispatch('logout')
			}
		}
	}
</script>
