import Vue from 'vue'

Vue.filter('mask', (value, type) => {
	if (!value)
		return value

	switch (type) {
		case 'mobile':
			value = 'xxxxxx' + value.substr(-4)

			break
		case 'email':
			let emailArr = value.split('@')
			let lettersArr = emailArr[0].split('')

			value = lettersArr.map((letter, index) => {
				return [0, (lettersArr.length - 1)].includes(index) ? letter : '*'
			}).join('') + '@' + emailArr[1]

			break
	}

	return value
})

Vue.filter('formatIfEmpty', (value, str) => {
	return !value ? str : value
})
