<template>
	<v-ons-page>
		<v-ons-toolbar>
			<div class="left">
				<v-ons-back-button />
			</div>
			<div class="center text-capitalize">
				{{ title }}
			</div>
		</v-ons-toolbar>
		<v-ons-list v-if="item">
			<div class="edit-item-form p-3 display-block">
				<ValidationObserver ref="validator">
					<p class="m-0 title text-capitalize">
						{{ $t('item details') }}
					</p>
					<hr class="under">
					<v-ons-list-item modifier="nodivider">
						<v-ons-col class="mr-1">
							<p class="m-0 input-title text-capitalize">
								{{ $t('name') }} <span class="text-danger">*</span>
							</p>
							<ValidationProvider
								v-slot="{ errors }"
								:name="$t('item name')"
								rules="required"
							>
								<v-ons-input
									v-model="itemName"
									name="item-name"
									:value="item.name"
								/>
								<small class="text-danger text-capitalize">
									{{ errors[0] }}
								</small>
							</ValidationProvider>
						</v-ons-col>
						<v-ons-col class="ml-1">
							<p class="m-0 input-title text-capitalize">
								{{ $tc('category', 1) }} <span class="text-danger">*</span>
							</p>
							<ValidationProvider
								v-slot="{ errors }"
								:name="$t('category name')"
								rules="required"
							>
								<v-ons-input
									v-model="category.name"
									name="category-name"
									readonly
									:value="category.name"
									@click="selectCategory"
								/>
								<small class="text-danger text-capitalize">
									{{ errors[0] }}
								</small>
							</ValidationProvider>
						</v-ons-col>
					</v-ons-list-item>
					<p class="m-0 mt-3 title text-capitalize">
						{{ $t('item variation details') }}
					</p>
					<hr class="under">
					<v-ons-list-item modifier="nodivider">
						<v-ons-col class="mr-1">
							<p class="m-0 input-title text-capitalize">
								{{ $t('name') }} <span class="text-danger">*</span>
							</p>
							<ValidationProvider
								v-slot="{ errors }"
								:name="$t('item variation name')"
								rules="required"
							>
								<v-ons-input
									v-model="variationName"
									name="variation-name"
								/>
								<small class="text-danger text-capitalize">
									{{ errors[0] }}
								</small>
							</ValidationProvider>
						</v-ons-col>
						<v-ons-col class="ml-1">
							<p class="m-0 input-title">
								SKU <span class="text-danger">*</span>
							</p>
							<ValidationProvider
								v-slot="{ errors }"
								name="sku"
								rules="required"
							>
								<v-ons-input
									v-model="sku"
									name="sku"
								/>
								<small class="text-danger text-capitalize">
									{{ errors[0] }}
								</small>
							</ValidationProvider>
						</v-ons-col>
					</v-ons-list-item>
					<v-ons-list-item
						v-if="appVersionNumber >= 168"
						modifier="nodivider"
					>
						<v-ons-col>
							<p class="m-0 input-title text-capitalize">
								{{ $t('description') }}
							</p>
							<v-ons-input
								v-model="description"
								name="description"
								class="w-100"
							/>
						</v-ons-col>
					</v-ons-list-item>
					<v-ons-list-item
						v-if="merchant.businessType !== 'textile'"
						modifier="nodivider"
					>
						<v-ons-col class="mr-1">
							<p class="m-0 input-title text-capitalize">
								{{ $t('food type') }} <span
									v-if="merchant.businessType !== 'grocery'"
									class="text-danger"
								>*</span>
							</p>
							<ValidationProvider
								v-slot="{ errors }"
								:name="$t('food type')"
								:rules="{
									required: merchant.businessType !== 'grocery'
								}"
							>
								<v-ons-input
									v-model="selectedFoodType"
									:value="selectedFoodType"
									readonlytype="number"
									readonly
									name="food-type"
									@click="foodTypeListVisible=true"
								/>
								<small class="text-danger text-capitalize">
									{{ errors[0] }}
								</small>
							</ValidationProvider>
						</v-ons-col>
						<v-ons-col class="ml-1">
							<p class="m-0 input-title text-capitalize">
								{{ $t('unit of measurement') }}<span class="text-danger">*</span>
							</p>
							<ValidationProvider
								v-slot="{ errors }"
								:name="$t('unit of measurement')"
								rules="required"
							>
								<v-ons-input
									v-model="selectedUnitOfMeasurement"
									:value="selectedUnitOfMeasurement"
									readonlytype="number"
									readonly
									name="food-type"
									@click="unitOfMeasurementListVisible=true"
								/>
								<small class="text-danger text-capitalize">
									{{ errors[0] }}
								</small>
							</ValidationProvider>
						</v-ons-col>
					</v-ons-list-item>
					<v-ons-list-item modifier="nodivider">
						<v-ons-col
							v-if="!item.item_id"
							class="mr-1"
						>
							<p class="m-0 input-title text-capitalize">
								{{ $t('stock') }} <span class="text-danger">*</span>
							</p>
							<ValidationProvider
								v-slot="{ errors }"
								:name="$t('stock')"
								rules="required"
							>
								<v-ons-input
									v-model="stock"

									type="number"
									name="stock"
								/>
								<small class="text-danger text-capitalize">
									{{ errors[0] }}
								</small>
							</ValidationProvider>
						</v-ons-col>
						<v-ons-col class="ml-1">
							<p class="m-0 input-title text-capitalize">
								{{ $t('barcode') }}
							</p>
							<ValidationProvider
								v-slot="{ errors }"
								:name="$t('barcode')"
								rules="alpha_num"
							>
								<v-ons-input
									v-model="barcode"
									name="barcode"
								/>
								<small class="text-danger text-capitalize">
									{{ errors[0] }}
								</small>
							</ValidationProvider>
						</v-ons-col>
					</v-ons-list-item>
					<v-ons-list-item modifier="nodivider">
						<v-ons-col class="mr-1">
							<p class="m-0 input-title text-capitalize">
								{{ $t('pricing type') }} <span class="text-danger">*</span>
							</p>
							<ValidationProvider
								v-slot="{ errors }"
								:name="$t('pricing type')"
								rules="required"
							>
								<v-ons-input
									v-model="pricingType"
									:value="pricingType"
									readonlytype="number"
									readonly
									name="pricing-type"
									@click="selectPriceType"
								/>
								<small class="text-danger text-capitalize">
									{{ errors[0] }}
								</small>
							</ValidationProvider>
						</v-ons-col>
						<v-ons-col class="ml-1">
							<p class="m-0 input-title text-capitalize">
								{{ $t('mrp') }}
							</p>
							<ValidationProvider
								v-slot="{ errors }"
								:name="$t('mrp')"
								:rules="{
									min_value: 0
								}"
							>
								<v-ons-input
									v-model="mrp"
									type="number"
									name="mrp"
								/>
								<small class="text-danger text-capitalize">
									{{ errors[0] }}
								</small>
							</ValidationProvider>
						</v-ons-col>
					</v-ons-list-item>
					<v-ons-list-item modifier="nodivider">
						<v-ons-col class="mr-1">
							<p class="m-0 input-title text-capitalize">
								{{ $t('buying price') }}
							</p>
							<ValidationProvider
								v-slot="{ errors }"
								:name="$t('buying price')"
								rules="decimal:2|min:.1"
							>
								<v-ons-input
									v-model="buyingPrice"
									type="number"
									name="Seprice"
								/>
								<small class="text-danger text-capitalize">
									{{ errors[0] }}
								</small>
							</ValidationProvider>
						</v-ons-col>
						<v-ons-col class="ml-1">
							<p class="m-0 input-title text-capitalize">
								{{ $t('selling price') }} <span class="text-danger">*</span>
							</p>
							<ValidationProvider
								v-slot="{ errors }"
								:name="$t('selling price')"
								rules="required|decimal:2|greaterThanZero"
							>
								<v-ons-input
									v-model="price"
									type="number"
									name="price"
								/>
								<small class="text-danger text-capitalize">
									{{ errors[0] }}
								</small>
							</ValidationProvider>
						</v-ons-col>
					</v-ons-list-item>
					<v-ons-list-item modifier="nodivider">
						<ValidationProvider
							v-slot="{ errors }"
							:name="$tc('tax', 2)"
							rules="uniqueTaxType"
						>
							<v-ons-col class="ml-1">
								<p class="m-0 input-title text-capitalize">
									{{ $tc('tax', 2) }}
								</p>
								<ons-input
									v-model="selectedTaxes"
									name="taxes"
									:value="selectedTaxText"
									readonly
									class="tax-list"
									@click="selectTaxes"
								/>
							</v-ons-col>
							<small class="text-danger text-capitalize">
								{{ errors[0] }}
							</small>
						</ValidationProvider>
					</v-ons-list-item>
					<v-ons-list-item
						v-if="!item.id"
						modifier="nodivider"
					>
						<v-ons-col class="ml-1">
							<label class="left">
								<ons-checkbox
									:value="trackInventory"
									:checked="trackInventory"
									@change="trackInventory=$event.target.checked"
								/>
							</label>
							<label
								class="center text-capitalize"
							>
								{{ $t('track inventory') }}
							</label>
						</v-ons-col>
					</v-ons-list-item>
					<v-ons-list-item modifier="nodivider">
						<v-ons-col class="ml-1">
							<label class="left">
								<ons-checkbox
									:value="onlineTrackInventory"
									:checked="onlineTrackInventory"
									@change="onlineTrackInventory=$event.target.checked"
								/>
							</label>
							<label
								class="center text-capitalize"
							>
								{{ $t('track online inventory') }}
							</label>
						</v-ons-col>
					</v-ons-list-item>
					<div v-if="merchant.subscription && merchant.subscription.products.online_store">
						<v-ons-list-item modifier="nodivider">
							<v-ons-col class="ml-1">
								<label class="left">
									<ons-checkbox
										:value="isAvailable"
										:checked="isAvailable"
										@change="isAvailable=$event.target.checked"
									/>
								</label>
								<label
									class="center text-capitalize"
								>
									{{ $t('is available') }}
								</label>
							</v-ons-col>
						</v-ons-list-item>
					</div>

					<v-ons-list-item modifier="nodivider">
						<v-ons-button
							ref="submitBtn"
							modifier="large"
							class="position-fixed rounded-0 text-capitalize"
							style="bottom: 0; left: 0; right: 0; z-index: 5;height: 40px;line-height: 40px;"
							@click="createItem($event)"
						>
							{{ $t('submit') }}
						</v-ons-button>
					</v-ons-list-item>
				</ValidationObserver>
			</div>
		</v-ons-list>

		<v-ons-action-sheet
			:visible.sync="priceTypeListVisible"
			cancelable
			class="text-capitalize"
			:title="$t('pricing type')"
		>
			<v-ons-list
				class="overflow-auto"
				style="max-height: 250px"
			>
				<v-ons-list-item
					v-for="(priceType, index) in pricingTypes"
					:key="index"
					modifier="nodivider"
					class="bg-white"
					tappable
				>
					<label class="left">
						<ons-radio
							name="selected-price-type"
							:input-id="`price-type-${index}`"
							:value="JSON.stringify(priceType)"
							:checked="(pricingType).toLowerCase() === (priceType.text).toLowerCase()"
							@change="priceTypeSelecetd($event)"
						/>
					</label>
					<label
						:for="`price-type-${index}`"
						class="center"
					>
						{{ $tc(priceType.text, priceType.pluralizationIndex || 1) }}
					</label>
				</v-ons-list-item>
			</v-ons-list>
		</v-ons-action-sheet>

		<v-ons-action-sheet
			:visible.sync="taxListVisible"
			cancelable
			class="text-capitalize"
			:title="$tc('tax', 2)"
		>
			<v-ons-list
				class="overflow-auto pb-5"
				style="max-height: 250px"
			>
				<v-ons-list-item
					v-for="(taxItem, index) in taxes"
					:key="index"
					modifier="nodivider"
					class="bg-white"
					tappable
				>
					<label class="left">
						<ons-checkbox
							:input-id="`tax-${index}`"
							:value="JSON.stringify(taxItem)"
							name="selected-taxes"
							:checked="selectedTaxes.findIndex(selectedTax => selectedTax.id === taxItem.id) !== -1"
							@change="setSelectedTaxList(taxItem)"
						/>
					</label>
					<label
						:for="`tax-${index}`"
						class="center"
					>
						{{ taxItem.name }}
					</label>
				</v-ons-list-item>
			</v-ons-list>
			<v-ons-bottom-toolbar
				class="bg-white px-2"
				modifier="transparent"
			>
				<v-ons-button
					modifier="large"
					@click="taxListVisible=false"
				>
					{{ $t('done') }}
				</v-ons-button>
			</v-ons-bottom-toolbar>
		</v-ons-action-sheet>
		<v-ons-action-sheet
			:visible.sync="foodTypeListVisible"
			cancelable
			class="text-capitalize"
			:title="$t('food type')"
		>
			<v-ons-list
				class="overflow-auto"
				style="max-height: 250px"
			>
				<v-ons-list-item
					v-for="(food, index) in foodTypes"
					:key="index"
					modifier="nodivider"
					class="bg-white"
					tappable
				>
					<label class="left">
						<ons-radio
							:input-id="`food-${index}`"
							:value="JSON.stringify(food)"
							name="selected-food-type"
							:checked="(selectedFoodType).toLowerCase() === (food.text).toLowerCase()"
							@change="foodTypeSelecetd($event)"
						/>
					</label>
					<label
						:for="`food-${index}`"
						class="center"
					>
						{{ $t(food.text) }}
					</label>
				</v-ons-list-item>
			</v-ons-list>
		</v-ons-action-sheet>
		<v-ons-action-sheet
			:visible.sync="unitOfMeasurementListVisible"
			cancelable
			class="text-capitalize"
			:title="$t('unit of measurement')"
		>
			<v-ons-list
				class="overflow-auto"
				style="max-height: 250px"
			>
				<v-ons-list-item
					v-for="(measurement, index) in unitOfMeasurements"
					:key="index"
					modifier="nodivider"
					class="bg-white"
					tappable
				>
					<label class="left">
						<ons-radio
							:input-id="`unitOfMeasurements-${index}`"
							:value="JSON.stringify(measurement)"
							name="selected-measurement"
							:checked="(unitOfMeasurement).toLowerCase() === (measurement.text).toLowerCase()"
							@change="measurementSelecetd($event)"
						/>
					</label>
					<label
						:for="`unitOfMeasurements-${index}`"
						class="center"
					>
						{{ $t(measurement.text) }}
						<span v-if="measurement.unit">({{ measurement.unit }})</span>
					</label>
				</v-ons-list-item>
			</v-ons-list>
		</v-ons-action-sheet>
	</v-ons-page>
</template>

<script>
	import Categories from '~/components/categories'
	import { ValidationObserver, ValidationProvider } from "vee-validate"

	export default {
		name: 'EditItem',
		components: {
			ValidationObserver,
			ValidationProvider
		},
		props: {
			title: {
				type: String,
				required: true
			},
			item: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				pricingTypes: [
					{
						text: 'fix',
						value: 'fixed',
						pluralizationIndex: 2
					},
					{
						text: 'variable',
						value: 'variable'
					},
					{
						text: 'multi',
						value: 'multi'
					}
				],
				priceTypeListVisible: false,
				selectedTaxText: '',
				taxListVisible: false,
				selectedCategoryId: 0,
				taxes: [],
				itemId: '',
				itemName: '',
				category: '',
				variationId: '',
				inventoryId: '',
				variationName: '',
				sku: '',
				barcode: '',
				pricingType: '',
				price: '',
				mrp: '',
				description: '',
				stock: '',
				selectedTaxes: [],
				foodType:'',
				selectedFoodType:'',
				foodTypes:[
					{
						text:'veg',
						value:'veg'
					},
					{
						text:'non veg',
						value:'nveg'
					},
					{
						text:'egg',
						value:'egg'
					}
				],
				foodTypeListVisible:false,
				buyingPrice:'',
				unitOfMeasurements:[
					{
						text: 'count',
						value: 'count'
					},
					{
						text: 'length',
						value: 'length'
					},
					{
						text: 'volume',
						value: 'volume',
						unit: 'L'
					},
					{
						text: 'weight',
						value: 'weight',
						unit: 'KG'
					},
					{
						text: 'time',
						value: 'time'
					},
					{
						text: 'area',
						value: 'area'
					},
					{
						text: 'other',
						value: 'other'
					}
				],
				unitOfMeasurement:'',
				selectedUnitOfMeasurement:'',
				unitOfMeasurementListVisible:false,
				onlineTrackInventory:false,
				isAvailable:false,
				trackInventory:false,
			}
		},
		computed: {
			pageStack: {
				get() {
					return this.$store.state.pageStack
				},
				set(pageStack) {
					this.$store.commit('setPageStack', pageStack)
				}
			},
			appVersion() {
				return this.$store.state.appVersion
			},
			appVersionNumber() {
				return parseInt(this.appVersion.replace(/\./g, ''))
			},
			deviceId() {
				return this.$store.state.deviceId
			},
			locationId() {
				return this.$store.state.locationId
			},
			merchant() {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			selectedVariation: {
				get() {
					return this.$store.state.selectedVariation
				},
				set(variation) {
					this.$store.commit('setSelectedVariation', variation)
				}
			}
		},
		beforeMount() {
			this.getData('getTaxes', 'taxes')
			if(this.item){
				const item = JSON.parse(JSON.stringify(this.item))
				if (item.id) {
					this.itemId = item.item_id
					this.itemName = item.item_name
					this.category = {
						id: item.category.id,
						name: item.category.name
					}

					this.selectedCategoryId = this.item.category.id
					this.variationId = item.id
					this.inventoryId = item.inventory_id
					this.variationName = item.name
					this.sku = item.sku
					this.barcode = item.barcode
					this.pricingType = item.pricing_type
					this.price = item.price
					this.stock = item.stock
					this.selectedTaxes = item.tax
					this.buyingPrice = item.custom_attributes.buying_price ? item.custom_attributes.buying_price : ''
					this.mrp = item.custom_attributes.mrp
					this.description = item.description
					let filteredFoodType = this.foodTypes.filter(f=> item.food_type === f.value)
					if (filteredFoodType.length) {
						this.foodType = filteredFoodType[0].value
						this.selectedFoodType = filteredFoodType[0].text
					}
					this.trackInventory = item.custom_attributes.track_inventory?item.custom_attributes.track_inventory:false
					if (item.unit_measure_type) {
						let unitMeasureType = this.unitOfMeasurements.filter(u=> item.unit_measure_type===u.value)
						if (unitMeasureType) {
							this.unitOfMeasurement = unitMeasureType[0].value
							this.selectedUnitOfMeasurement = unitMeasureType[0].text
						}
					}
					this.onlineTrackInventory = item.custom_attributes.online_track_inventory ? item.custom_attributes.online_track_inventory : false
					this.isAvailable = item.custom_attributes.is_available ? item.custom_attributes.is_available : false
					this.getSelectedTaxesList()
				}
			}
		},
		destroyed () {
			this.selectedVariation = null
		},
		methods: {
			getData(fnName, varName, searchTerm) {
				const data = this.$bridge[fnName](this.deviceId, JSON.stringify({
					search_term: searchTerm
				}))

				this[varName] = (typeof data === 'string' ? JSON.parse(data) : data).data
			},
			selectPriceType(){
				this.priceTypeListVisible = true
			},
			priceTypeSelecetd($event){
				this.priceTypeListVisible = false
				this.pricingType = JSON.parse($event.target.value).value
			},
			foodTypeSelecetd($event){
				this.foodTypeListVisible = false
				this.foodType = JSON.parse($event.target.value).value
				this.selectedFoodType = JSON.parse($event.target.value).text
			},
			measurementSelecetd($event){
				this.unitOfMeasurementListVisible = false
				this.unitOfMeasurement = JSON.parse($event.target.value).value
				this.selectedUnitOfMeasurement  = JSON.parse($event.target.value).text
			},
			selectTaxes(){
				this.taxListVisible = true
			},
			getSelectedTaxesList(){
				var taxResult = ''
				for (var i = 0; i < this.selectedTaxes.length; i++) {
					if(i > 0 && i < this.selectedTaxes.length){
						taxResult += ', ' + this.selectedTaxes[i].name
					} else {
						taxResult += this.selectedTaxes[i].name
					}
				}

				this.selectedTaxText = taxResult;
			},
			setSelectedTaxList(taxItem){
				for (var i = 0; i < this.selectedTaxes.length; i++) {
					if(this.selectedTaxes[i] && taxItem.id === this.selectedTaxes[i].id){
						this.selectedTaxes.splice(i, 1)
						this.getSelectedTaxesList()
						return true
					}
				}
				this.selectedTaxes.push(taxItem)
				this.getSelectedTaxesList()
			},
			selectCategory() {
				this.pageStack.push({
					extends: Categories,
					onsNavigatorProps: {
						categoryId: this.selectedCategoryId,
						emitCategory: (category) => {
							this.category = category
							this.selectedCategoryId = category.id
						}
					}
				})
			},
			async createItem($event) {
				if (await this.$refs.validator.validate() && this.$offline.state === 'up') {
					const el = $event.currentTarget

					el.disabled = true
					el.classList.add('running')

					const inventory = {
						id: this.inventoryId,
						location_id: this.locationId,
						pricing_type: this.pricingType,
						price: parseFloat(this.price),
						buying_price:parseFloat(this.buyingPrice) || 0,
						tax: this.selectedTaxes.map(tax => tax.id),
						mrp: parseFloat(this.mrp) || 0,
						is_available:this.isAvailable ? 1 : 0,
						online_track_inventory:this.onlineTrackInventory ? 1 : 0,
						track_inventory:this.trackInventory,
						is_active: true
					}

					if (!this.item.item_id) {
						inventory.quantity_available = parseFloat(this.stock)
					}

					this.$axios[this.itemId ? '$patch' : '$post']('/api/pos/v1/item', {
						id: this.itemId,
						name: this.itemName,
						category_id: this.category.id,
						location_id: this.locationId,
						is_active: true,
						variation: [{
							id: this.variationId,
							name: this.variationName,
							description: this.description,
							sku: this.sku,
							barcode: this.barcode,
							unit_measure_type:this.unitOfMeasurement,
							food_type:this.foodType,
							type: 'sale',
							is_active: true,
							inventory: [inventory]
						}]
					}).then(response => {
						el.disabled = false
						el.classList.remove('running')
						if (response.status === 'success') {
							this.$bridge.customInsertOrUpdate(
								'Item', this.deviceId,this.locationId, this.$bridge.getName() === 'ANDROID' ?
									JSON.stringify([response.data.items]) : [response.data.items]
							)
							this.$ons.notification.toast(`${this.itemId ? this.$t('itemUpdated') : this.$t('itemCreated')}`, {
								timeout: 3000
							})
							this.$root.$emit('getItems')
							this.pageStack.pop()
						} else {
							this.$ons.notification.toast(this.$t('oopsError.text'), {
								timeout: 3000
							})
						}
					}).catch(err => {
						if (err.response && err.response.status === 422) {
							const errors = {}
							for (let e in err.response.data.data.errors) {
								errors[e.split('.').pop()] = [err.response.data.data.errors[e].replace('variation.0.', '')]
								this.$refs.validator.setErrors(errors)
							}
						}
						el.disabled = false
						el.classList.remove('running')
					})
				} else if (this.$offline.state === 'down') {
					this.$ons.notification.toast(this.$t('offlineError.text'), {
						timeout: 3000
					})
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.title{
		font-size: 14px;
		color: #666;
	}
	hr.under{
		border-top: 1px solid #eee;
	}
	p.input-title{
		font-size: 14px;
		color: #afafaf;
	}
	.tax-list{
		width: 100%;
		font-size: 12px;
	}
</style>
