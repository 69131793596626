const state = () => ({
	locale: 'en',
	product: {
		name: 'POSBytz',
		website: 'https://posbytz.com'
	},
	partner: {
		name: 'POSBytz',
		logo: '/images/posbytz-logo.png',
		logo_dark: '/images/posbytz-logo.png',
		logo_square: '/images/posbytz-logo.png',
		domain: 'https://posbytz.com'
	},
	appVersion: process.env.VERSION,
	mainMenu: [],
	pageStack: [],
	syncModels: {
		merchantDetails: {
			name: 'merchant details',
			icon: 'fa-cog',
			sync: true
		},
		employees: {
			name: 'employee',
			modelName: 'Employee',
			pluralizationIndex: 2,
			icon: 'fa-user-tie',
			percent: null
		},
		devices: {
			name: 'device',
			modelName: 'Device',
			pluralizationIndex: 2,
			icon: 'fa-mobile',
			percent: null
		},
		floors: {
			name: 'floor',
			modelName: 'Floor',
			pluralizationIndex: 2,
			icon: 'fa-building',
			percent: null
		},
		floorTables: {
			name: 'table',
			modelName: 'Table',
			pluralizationIndex: 2,
			name: 'table',
			icon: 'fa-utensils',
			percent: null
		},
		merchantCustomerGroups: {
			name: 'customer groups',
			modelName: 'CustomerGroup',
			name: 'customer groups',
			icon: 'fa-users',
			percent: null
		},
		merchantCustomers: {
			name: 'customer',
			modelName: 'Customer',
			name: 'customer',
			pluralizationIndex: 2,
			icon: 'fa-user',
			percent: null
		},
		categories: {
			name: 'categories',
			modelName: 'Category',
			name: 'category',
			pluralizationIndex: 2,
			icon: 'fa-sitemap',
			percent: null
		},
		taxes: {
			name: 'tax',
			modelName: 'Tax',
			pluralizationIndex: 2,
			icon: 'fa-file-invoice-dollar',
			percent: null
		},
		discounts: {
			name: 'discount',
			modelName: 'Discount',
			name: 'discount',
			pluralizationIndex: 2,
			icon: 'fa-percent',
			percent: null
		},
		merchantCharges: {
			name: 'charges',
			modelName: 'Charges',
			icon: 'fa-file-invoice-dollar',
			percent: null
		},
		merchantPriceCategories: {
			name: 'price category',
			modelName: 'PriceCategory',
			pluralizationIndex: 2,
			icon: 'fa-tags',
			percent: null
		},
		items: {
			name: 'item',
			modelName: 'Item',
			pluralizationIndex: 2,
			icon: 'fa-list',
			percent: null
		},
		itemVariationGroups: {
			name: 'item variation groups',
			modelName: 'ItemVariationGroup',
			icon: 'fa-edit',
			percent: null
		},
		merchantPaymentMethods: {
			name: 'payment method',
			modelName: 'PaymentMethod',
			pluralizationIndex: 2,
			icon: 'fa-credit-card',
			percent: null
		}
	},
	uuid: null,
	deviceId: null,
	locationId: null,
	locationName: null,
	isLoggedIn: false,
	loginToken: null,
	merchant: {},
	location: {},
	settings: {
		sale: {},
		general: {},
		receipt: {},
		kot_receipt: {},
		denominations: [],
		barcode: {}
	},
	employees: [],
	employee: null,
	cashDrawer: {
		show: false,
		close: true,
		type: ''
	},
	cashDrawerShift: null,
	selectedCategoryId: null,
	selectedPriceCategory: {
		id: null,
		name: 'Default'
	},
	selectedMerchant: null,
	selectedItem: null,
	selectedVariation: null,
	selectedCartVariation: null,
	selectedCartVariationIndex: null,
	itemPopover: {
		show: false,
		target: null
	},
	showItemCustomizer: false,
	favorites: [],
	charges: [],
	cart: {
		items: [],
		price: {
			subtotal: 0,
			tax: 0,
			inclusiveTaxTotal: 0,
			exclusiveTaxTotal: 0,
			taxes: [],
			discount: 0,
			discounts: [],
			total: 0
		},
		priceCategory: null,
		customer: {},
	},
	showOrderConfirmation: true,
	showConfirmWOPrint: true,
	printerSettings: {
		paperSize: 2,
		cutPaperAfterPrint: true,
		openCashboxAfterPrint: false,
		printShiftSummaryAfterClockout: true,
		printShiftSummaryCategoryItems: false,
		printShiftSummaryDenominations: false,
		reprintKot: false,
		scaleFactor: 2,
		paperFeedLength: 1,
		useDefaultKotPrinter: true,
	},
	kots: [],
	selectedTable: null,
	selectedOrder: null,
	lastSelectedTableId: null,
	denominations: [],
	orderReceipt: {
		print: false,
		type: '',
		data: null
	},
	shiftReceipt: {
		print: false,
		source: ''
	},
	orderInvoice: null,
	discounts: [],
	showDiscount: false,
	showDynamicDiscount: false,
	showCharges: false,
	showOrderDiscounts: false,
	selectedDiscount: null,
	additionalInfo: {},
	selectedOnlineOrder: null,
	newOnlineOrdersCount: 0,
	onHold: [],
	isWaiterAppRunning: false,
	autoSyncEnabled: false,
	appMode: '',
	isWeighingScaleEnabled: false,
	selectedWeighingScale: 'USB',
	selectedBluetoothWeighingDevice: '',
	selectedBluetoothPrintingDevice: '',
	weighingScale: {
		baudRate: 9600
	},
	bluetoothPrinterSelected: false,
	isEzSwype: false,
	showCustomSale: false,
	selectedTables: [],
	searchBarVisibility: false,
	loopNotificationSound: true,
	itemView: {
		toggleViews: false,
		items: false,
		categoryFilterStyle: 'sidebar' // alternative: bottom-sheet
	},
	updateModel: {
		visible: false
	},
	wPosPaymentEnabled: false,
	isTidyPayEnabled: false,
	paymentGateway: {},
	totp: {
		dialog: false,
		value: '',
		type: 'verify-totp',
		title: 'Authentic',
		message: ''
	},
	kitchenDisplayZoomLevel: 1,
	tip: null,
	b4u: {
		b4uPaymentEnabled: false,
		b4uCredentials: null
	},
	deviceOnline: true,
	receiptPreview: null
})

export default state