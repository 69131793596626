export default ({}, inject) => {
	if (typeof window.require === 'function') {
		const electron = window.require('electron')

		if (electron)
			inject('bridge', electron.remote.require('./bridge'))
	} else if (typeof bridge === 'object') {
		inject('bridge', bridge)
		// delete window.bridge
	}
}
