export default {
	scrollTop(el) {
		let scrollStep = -el.scrollTop / (200 / 15)
		let scrollInterval = setInterval(() => {
			if (el.scrollTop != 0)
				el.scrollBy(0, scrollStep)
			else
				clearInterval(scrollInterval);
		}, 15)
	},
	activateCard: $event => {
		$event.currentTarget.closest('.card-container, .card-deck').querySelectorAll('.card')
			.forEach(card => {
				if (card.isEqualNode($event.currentTarget)) {
					card.classList.remove('bg-white')
					card.classList.add('active', 'bg-primary', 'text-white')
				} else {
					card.classList.remove('active', 'bg-primary', 'text-white')
					card.classList.add('bg-white')
				}
			})
	}
}
