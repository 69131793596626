<template>
	<v-ons-page>
		<v-ons-toolbar>
			<div class="left">
				<div v-if="merchant.subscription && merchant.subscription.expires_in_seconds > 0">
					<v-ons-back-button />
				</div>
			</div>
			<div class="center text-capitalize">
				{{ $t('subscriptionModal.title') }}
			</div>
		</v-ons-toolbar>
		<div class="background bg-white" />
		<v-ons-row class="h-100 p-4">
			<v-ons-col vertical-align="center">
				<div class="text-center mb-4">
					<i class="la la-warning la-4x text-danger" />
					<h4>
						<span
							v-if="merchant.subscription && merchant.subscription.expires_in_seconds <= 0"
							class="text-capitalize"
						>
							{{ $tc('subscriptionModal.expiryTitle', 1) }}
						</span>
						<span
							v-else-if="merchant.subscription && merchant.subscription.expires_in_days"
							class="text-capitalize"
						>
							{{ $tc('subscriptionModal.expiryTitle', (merchant.subscription.expires_in_days > 1 ? 3 : 2), { "0": merchant.subscription.expires_in_days }) }}
						</span>
						<span
							v-else
							class="text-capitalize"
						>
							{{ $tc('subscriptionModal.expiryTitle', 4) }}
						</span>
					</h4>
					<p class="mb-0 text-capitalize">
						{{ $t('subscriptionModal.text1', { "0": partner.name }) }}
						<a
							href="#"
							@click="$bridge.openExternalUrl(backendDomain)"
						>
							{{ backendDomain }}
						</a>
						{{ $t('subscriptionModal.text2') }}
					</p>
					<v-ons-row class="mt-4">
						<v-ons-button
							:disabled="processing"
							class="w-100 mb-4 text-capitalize"
							@click="checkSubscriptionStatus"
						>
							{{ $t('subscriptionModal.checkButton') }}
							<span
								v-if="processing"
								class="float-right"
							>
								<v-ons-icon
									icon="md-spinner"
									spin
									size="18px"
								/>
							</span>
						</v-ons-button>
						<v-ons-button
							v-if="merchant.subscription && merchant.subscription.expires_in_seconds > 0"
							:disabled="processing"
							class="w-100 mb-4 text-capitalize"
							@click="proceedToCheckout"
						>
							{{ $t('proceed') }}
						</v-ons-button>
					</v-ons-row>
				</div>
			</v-ons-col>
		</v-ons-row>
	</v-ons-page>
</template>
<script>
	export default {
		name: 'Subscription',
		data() {
			return {
				backendDomain: process.env.BACKEND_DOMAIN,
				processing:false
			}
		},
		computed: {
			pageStack: {
				get() {
					return this.$store.state.pageStack
				},
				set(pageStack) {
					this.$store.commit('setPageStack', pageStack)
				}
			},
			merchant() {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			partner() {
				return this.$store.state.partner
			}
		},
		methods:{
			checkSubscriptionStatus(){
				this.processing = true
				this.sync(this.getSyncOptions('merchantDetails'))
				.then(res => {
					this.processing = false
				}).catch(err => {
					console.error(err)
					this.processing = false
				})
			},
			proceedToCheckout(){
				this.pageStack.pop()
			}
		}
	}
</script>
