<template>
	<v-ons-popover
		:visible.sync="itemPopover.show"
		:target="itemPopover.target"
		direction="down"
		cancelable
		:cover-target="true"
		@posthide="() => {}"
	>
		<v-ons-list>
			<v-ons-list-item
				v-if="($offline.state === 'up' && !parentMerchant.isCloudKitchen && employeePolicy.sell && employeePolicy.sell.includes('edit item')) && selectedVariation"
				tappable
				@click="showEditItem"
			>
				<div
					class="left"
					style="min-width: 30px"
				>
					<v-ons-icon
						icon="fa-edit"
						class="text-muted"
					/>
				</div>
				<div class="center">
					<p class="m-0 text-capitalize">
						{{ $t('edit') }}
					</p>
				</div>
			</v-ons-list-item>
			<v-ons-list-item
				v-if="selectedItem"
				tappable
				@click="addToFavorites(selectedItem.id)"
			>
				<div
					class="left"
					style="min-width: 30px"
				>
					<v-ons-icon
						icon="fa-heart"
						class="text-muted"
					/>
				</div>
				<div class="center">
					<p class="m-0 text-capitalize">
						{{ favorites.includes(selectedItem.id) ? $t('remove') : $t('make') }} {{ $tc('favorite', 1) }}
					</p>
				</div>
			</v-ons-list-item>
		</v-ons-list>
	</v-ons-popover>
</template>

<script>
	import EditItem from './edit-item.vue'

	export default {
		computed: {
			pageStack: {
				get() {
					return this.$store.state.pageStack
				},
				set(pageStack) {
					this.$store.commit('setPageStack', pageStack)
				}
			},
			parentMerchant () {
				return this.$store.state.merchant
			},
			itemPopover: {
				get() {
					return this.$store.state.itemPopover
				},
				set(data) {
					this.$store.commit('setItemPopover', data)
				}
			},
			employeePolicy() {
				return this.$store.getters.employeePolicy
			},
			selectedItem: {
				get() {
					return this.$store.state.selectedItem
				},
				set(item) {
					this.$store.commit('setState', {
						key: 'selectedItem',
						value: item
					})
				}
			},
			selectedVariation: {
				get() {
					return this.$store.state.selectedVariation
				},
				set(variation) {
					this.$store.commit('setSelectedVariation', variation)
				}
			},
			favorites() {
				return this.$store.state.favorites
			},
		},
		methods: {
			addToFavorites(itemId) {
				const favorites = this.favorites.slice()
				if (itemId) {
					if (!favorites.includes(itemId))
						favorites.push(itemId)
					else
						favorites.splice(favorites.indexOf(itemId), 1)

					this.$store.commit('setState', {
						key: 'favorites',
						value: favorites,
						save: true
					})
				}

				this.cleanUp()
			},
			showEditItem() {
				this.resetPopover()
				this.$root.$emit('updateVariations',false)
				this.pageStack.push({
					extends: EditItem,
					onsNavigatorProps: {
						title: this.$t('edit item'),
						item: this.selectedItem
					}
				})
			},
			resetPopover() {
				this.$store.commit('setItemPopover', {
					show: false,
					target: null
				})
			},
			cleanUp() {
				this.selectedItem = null
				this.selectedVariation = null
				this.resetPopover()
			}
		}
	}
</script>

<style lang="css" scoped>
</style>
