import { io } from 'socket.io-client'

export default ({ store }, inject) => {
    const socket = io(process.env.SOCKET_DOMAIN, {
        autoConnect: false,
        transports: ['websocket', 'polling'] // use WebSocket first, if available
    })

    socket.on('connect', () => {
        if (store.state.locationId) {
            socket.emit('add-to-room', store.state.locationId)
        }
    })

    socket.on('connect_error', () => {
        // revert to classic upgrade
        socket.io.opts.transports = ['polling', 'websocket']
    })

    inject('socket', socket)
}