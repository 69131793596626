import { localize } from 'vee-validate'

const mutations = {
	setLocale(state, locale) {
		state.locale = locale
		this.$i18n.locale = locale
		localize(locale)
		this.$bridge.setLocalStorage('locale', locale)
	},
	setState(state, data) {
		state[data.key] = data.value

		if (data.save === true) {
			this.$bridge.setLocalStorage(
				data.key,
				typeof data.value === 'object' ? JSON.stringify(data.value) : data.value
			)
		} else if (data.save === false) {
			this.$bridge.removeLocalStorage(data.key)
		}
	},
	setPageStack(state, pageStack) {
		state.pageStack = pageStack
	},
	setSyncModels(state, syncModels) {
		state.syncModels = syncModels
	},
	setSettings(state, settings) {
		state.settings = Object.assign({}, state.settings, settings)
		this.$bridge.setLocalStorage('settings', JSON.stringify(state.settings))
	},
	resetCashDrawer(state) {
		state.cashDrawer = {
			show: false,
			close: true,
			type: ''
		}
	},
	setSelectedCategoryId(state, categoryId) {
		state.selectedCategoryId = categoryId
	},
	setSelectedVariation(state, variation) {
		state.selectedVariation = variation
	},
	setItemPopover(state, data) {
		state.itemPopover = data
	},
	setShowItemCustomizer(state, showItemCustomizer) {
		state.showItemCustomizer = showItemCustomizer
	},
	setCart(state, cart) {
		state.cart = Object.assign({}, state.cart, cart)
	},
	setCustomer(state, customer) {
		state.cart.customer = customer
	},
	resetCustomer(state) {
		state.cart.customer = {}
	},
	resetCart(state) {
		state.cart = {
			items: [],
			price: {
				subtotal: 0,
				tax: 0,
				discount: 0,
				total: 0
			},
			customer: {},
			watch: true
		}
		state.selectedDiscount = null
		state.tip = null
        state.charges = []
	},
	setPrinterSettings(state, data) {
		state.printerSettings = data
		this.$bridge.setLocalStorage('printerSettings', JSON.stringify(data))
		this.$bridge.setSelectedPrinter
		if (this.$bridge.setSelectedPrinter) {
			this.$bridge.setSelectedPrinter(JSON.stringify(data))
		}
	},
	resetPrinterSettings(state) {
		state.printerSettings = {
			...state.printerSettings,
			paperSize: 2,
			paperFeedLength: 1,
			cutPaperAfterPrint: true,
			openCashboxAfterPrint: false,
			printShiftSummaryAfterClockout: true,
			printShiftSummaryCategoryItems: false,
			printShiftSummaryDenominations: false,
			reprintKot: false
		}
	},
	setShowDiscount(state, data) {
		state.showDiscount = data
	},
	setSelectedOnlineOrder(state, selectedOnlineOrder) {
		state.selectedOnlineOrder = selectedOnlineOrder
	},
	setAppMode(state, appMode) {
		state.appMode = appMode
	},
	setPasscode(state, data) {
		if ("show" in data)
			state.passcode.show = data.show

		if ("value" in data)
			state.passcode.value = data.value

		if ("type" in data)
			state.passcode.type = data.type
	},
	resetPasscode(state) {
		state.passcode = {
			show: false,
			value: '',
			type: ''
		}
	},
	setEzSwype(state, status) {
		state.isEzSwype = status
		this.$bridge.setLocalStorage('isEzSwype', status)
	},
	setShowCustomSale(state, status) {
		state.showCustomSale = status
	},
}

export default mutations