import 'offline-js'

Offline.options = {
	checkOnLoad: true,
	checks: {
		xhr: {
			url: '/api/connect'
		}
	}
}

Offline.check()

export default ({}, inject) => {
	inject('offline', Offline)
}
