var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-ons-page',{attrs:{"modifier":"cash-drawer-page"}},[_c('span',{staticClass:"text-capitalize",attrs:{"slot":"title"},slot:"title"},[_vm._v("\n\t\t"+_vm._s(_vm.$t('cash drawer'))+"\n\t")]),_vm._v(" "),_c('v-ons-toolbar',[(_vm.cashDrawer.close)?_c('div',{staticClass:"left"},[_c('v-ons-back-button')],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"center text-capitalize"},[_vm._v("\n\t\t\t"+_vm._s(_vm.$t('cash drawer'))+"\n\t\t")])]),_vm._v(" "),_c('div',{staticStyle:{"padding":"10px"}},[_c('ValidationObserver',{ref:"validator"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('amount'),"rules":{
					required: true,
					decimal: 2,
					greaterThanZero: ['cash in', 'cash out'].includes(_vm.cashDrawer.type) ? true : false
				}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
				var errors = ref.errors;
return [_c('v-ons-input',{staticClass:"w-100",class:{
						'mb-3': _vm.cashDrawer.type !== 'closing'
					},attrs:{"type":"number","step":".01","name":"amount","placeholder":_vm.$t(("enter " + (_vm.cashDrawer.type) + " amount")),"float":""},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}}),_vm._v(" "),_c('small',{staticClass:"text-danger text-capitalize"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_vm._v(" "),(_vm.cashDrawer.type !== 'closing')?_c('div',[_c('v-ons-input',{staticClass:"w-100",class:{
					'mb-3': _vm.cashDrawer.type === 'cash out'
				},attrs:{"type":"text","name":"description","placeholder":_vm.$t('description'),"float":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_vm._v(" "),(_vm.cashDrawer.type === 'cash out')?_c('v-ons-select',{staticClass:"w-100",model:{value:(_vm.cashVia),callback:function ($$v) {_vm.cashVia=$$v},expression:"cashVia"}},_vm._l((_vm.paidForOptions),function(option,index){return _c('option',{key:index,domProps:{"value":option.value}},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$tc(option.text, option.pluralizationIndex || 1))+"\n\t\t\t\t")])}),0):_vm._e()],1):_vm._e(),_vm._v(" "),((_vm.$store.state.settings.denominations.length>1))?_c('a',{staticClass:"text-capitalize",staticStyle:{"margin-top":"10px","float":"right"},attrs:{"href":"#"},on:{"click":function () {
				_vm.addDenomination = true
			}}},[_vm._v("\n\t\t\t"+_vm._s(_vm.$tc('denomination', 3))+"\n\t\t")]):_vm._e(),_vm._v(" "),_c('v-ons-action-sheet',{attrs:{"title":_vm.$tc('denomination', 2),"modifier":"denominations-action-sheet-container","cancelable":"","visible":_vm.addDenomination},on:{"update:visible":function($event){_vm.addDenomination=$event},"posthide":function () {
				_vm.amount = _vm.denominationAmount !== null ? _vm.denominationAmount : _vm.amount
				_vm.addDenomination = false
			}}},[_c('div',{staticClass:"pb-5"},[_c('denominations',{on:{"update:amount":function($event){_vm.denominationAmount = $event}}})],1),_vm._v(" "),_c('v-ons-bottom-toolbar',{staticClass:"bg-white px-2",attrs:{"modifier":"transparent"}},[_c('v-ons-button',{attrs:{"modifier":"large"},on:{"click":function($event){_vm.addDenomination=false}}},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('done'))+"\n\t\t\t\t")])],1)],1),_vm._v(" "),_c('v-ons-bottom-toolbar',{staticStyle:{"height":"52px"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('v-ons-col',{staticStyle:{"padding":"10px"},attrs:{"vertical-align":"center"}},[(_vm.cashDrawer.close)?_c('v-ons-button',{staticClass:"text-capitalize",staticStyle:{"margin-right":"10px"},attrs:{"modifier":"large"},on:{"click":_vm.cancel}},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.$tc('cancel', 1))+"\n\t\t\t\t\t")]):_vm._e()],1),_vm._v(" "),_c('v-ons-col',{staticStyle:{"padding":"10px"},attrs:{"vertical-align":"center"}},[_c('v-ons-button',{staticClass:"text-capitalize",attrs:{"modifier":"large"},on:{"click":_vm.processShift}},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.$t('submit'))+"\n\t\t\t\t\t")])],1)],1)])],1),_vm._v(" "),_c('v-ons-alert-dialog',{staticClass:"text-capitalize",attrs:{"modifier":"rowfooter","visible":_vm.cashDrawerDialog},on:{"update:visible":function($event){_vm.cashDrawerDialog=$event}}},[_c('span',{staticClass:"text-capitalize",attrs:{"slot":"title"},slot:"title"},[_vm._v("\n\t\t\t"+_vm._s(_vm.$tc('success', 1))+"\n\t\t")]),_vm._v("\n\t\t"+_vm._s(_vm.$t(_vm.cashDrawer.type === 'starting' ? _vm.$t('cashShiftStarted') : (_vm.cashDrawer.type === 'cash in' ? _vm.$t('cashReceivedSuccess') : _vm.$t('cashPaidSuccess'))))+"\n\t\t"),_c('template',{slot:"footer"},[_c('v-ons-alert-dialog-button',{on:{"click":function($event){return _vm.$emit('pop')}}},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t('ok'))+"\n\t\t\t")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }