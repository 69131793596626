import Vue from 'vue'

import CashDrawer from '~/components/cash-drawer'
import ItemPopover from '~/components/sell/item-popover'
import TableOrdersAction from '~/components/table-orders-action'
import DynamicDiscountDialog from '~/components/dynamic-discount-dialog'
import ChargesDialog from '~/components/charges-dialog'

const ItemCustomizer = () =>
    import ('~/components/sell/item-customizer')
const Discounts = () =>
    import ('~/components/discounts')
const Tips = () =>
    import ('~/components/tips')
const Denominations = () =>
    import ('~/components/denominations')

Vue.component('item-customizer', ItemCustomizer)
Vue.component('discounts', Discounts)
Vue.component('tips', Tips)
Vue.component('cash-drawer', CashDrawer)
Vue.component('item-popover', ItemPopover)
Vue.component('table-orders-action', TableOrdersAction)
Vue.component('dynamic-discount-dialog', DynamicDiscountDialog)
Vue.component('charges-dialog', ChargesDialog)
Vue.component('denominations', Denominations)