<template>
	<v-ons-alert-dialog
		v-if="(!(source==='table' && pageStack.length === 4)) && showDynamicDiscount"
		modifier="rowfooter"
		:visible.sync="showDynamicDiscount"
		@preshow="prefill"
		@hide="resetForm"
	>
		<span
			slot="title"
		>
			{{ (selectedDynamicDiscountType === 'item') ? selectedCartVariation.name : $t('apply discount') }}
		</span>
		<div
			v-if="selectedDynamicDiscountType === 'item' && selectedDiscountType==='price'"
			class="text-capitalize"
			style="font-size: 13px;"
		>
			{{ applyDiscountOnEachItem ? $t('each item') : $t('item total') }}: {{ calculateCartItemTotal(selectedCartVariation, selectedDiscountType) }} ({{ $t('max discount') }})
		</div>
		<p
			class="text-capitalize"
			style="color: #FF8C00; font-size: 13px;"
		>
			{{ $t('discount type') }}
		</p>
		<ValidationObserver ref="validator">
			<ValidationProvider
				v-if="selectedDynamicDiscountType === 'item' ? cart.items[selectedCartVariationIndex] : selectedDynamicDiscountType === 'order'"
				v-slot="{ errors }"
				:name="$t('discount type')"
				rules="required|alpha_spaces"
			>
				<v-ons-select
					v-model="selectedDiscountType"
					class="w-100 mb-2"
				>
					<option
						v-for="(discount, index) in discountTypes"
						:key="index"
						name="discountType"
						:value="discount.value"
					>
						{{ $tc(discount.text, discount.pluralizationIndex || 1) }}
					</option>
				</v-ons-select>
				<small class="text-danger">
					{{ errors[0] }}
				</small>
			</ValidationProvider>
			<div
				v-if="selectedDiscountType"
			>
				<p
					class="text-capitalize"
					style="color: #FF8C00; font-size: 13px;"
				>
					{{ $t('discount value') }}
				</p>
				<ValidationProvider
					v-slot="{ errors }"
					:name="$t('discount value')"
					:rules="{
						required: true,
						decimal: 2,
						min_value: 0.01,
						max_value: `${calculateCartItemTotal(selectedDynamicDiscountType === 'item' ? cart.items[selectedCartVariationIndex] : cart.price, selectedDiscountType)}`
					}"
				>
					<v-ons-input
						v-model="selectedDiscountValue"
						type="number"
						min_value="0"
						name="discountValue"
						:max_value="100"
						class="w-100"
						float
					/>
					<small class="text-danger">
						{{ errors[0] }}
					</small>
				</ValidationProvider>
				<div
					v-if="selectedDynamicDiscountType === 'order' && selectedDiscountType === 'percentage'"
				>
					<p
						class="text-capitalize"
						style="color: #FF8C00; font-size: 13px;"
					>
						{{ $t('maximum discount') }}
					</p>
					<ValidationProvider
						v-slot="{ errors }"
						:name="$t('maximum discount')"
						:rules="{
							required: true,
							decimal: 2,
							min_value: 0.01,
						}"
					>
						<v-ons-input
							v-model="maximumDiscountValue"
							type="number"
							min_value="0"
							name="maximumDiscount"
							:max_value="100"
							class="w-100"
							float
						/>
						<small class="text-danger">
							{{ errors[0] }}
						</small>
					</ValidationProvider>
				</div>
				<v-ons-row
					v-if="selectedDynamicDiscountType === 'item' && selectedDiscountType === 'price' && settings.general.apply_discount_on_each_item"
					class="mt-2"
				>
					<label class="left">
						<v-ons-checkbox
							v-model="applyDiscountOnEachItem"
							:input-id="'checkbox'"
						/>
					</label>
					<label
						class="center"
						:for="'checkbox'"
					>
						{{ $t('apply discount on each item') }}
					</label>
				</v-ons-row>
			</div>
		</ValidationObserver>
		<template
			slot="footer"
		>
			<div class="px-2">
				<v-ons-alert-dialog-button
					class="text-capitalize"
					:disabled="disableSubmit"
					@click="applyDiscount"
				>
					{{ $t('submit') }}
				</v-ons-alert-dialog-button>
				<v-ons-alert-dialog-button
					class="text-capitalize"
					@click="closeDialog"
				>
					{{ $tc('cancel', 1) }}
				</v-ons-alert-dialog-button>
			</div>
		</template>
	</v-ons-alert-dialog>
</template>
<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate"

	export default {
		name: 'DynamicDiscountDialog',
		components: {
			ValidationObserver,
			ValidationProvider
		},
		props: {
			source: {
				type: String,
				required: true
			},
			selectedDynamicDiscountType: {
				type: String,
				default: 'item'
			}
		},
		data() {
			return {
				disableSubmit: false,
				discountTypes: [
					{ value: null, text: 'select discount type' },
					{ value: 'price', text: 'fix', pluralizationIndex: 2 },
					{ value: 'percentage', text: 'percentage' }
				],
				selectedDiscountType: null,
				selectedDiscountValue: null,
				maximumDiscountValue: null,
				applyDiscountOnEachItem: true
			}
		},
		computed: {
			pageStack: {
				get() {
					return this.$store.state.pageStack
				},
				set(pageStack) {
					this.$store.commit('setPageStack', pageStack)
				}
			},
			showDynamicDiscount: {
				get() {
					return this.$store.state.showDynamicDiscount
				},
				set(dynamicDiscount) {
					this.$store.commit('setState', {
						key: 'showDynamicDiscount',
						value: dynamicDiscount
					})
				}
			},
			selectedCartVariation: {
				get() {
					return this.$store.state.selectedCartVariation
				},
				set(variation) {
					this.$store.commit('setState', {
						key: 'selectedCartVariation',
						value: variation
					})
				}
			},
			selectedCartVariationIndex: {
				get() {
					return this.$store.state.selectedCartVariationIndex
				},
				set(index) {
					this.$store.commit('setState', {
						key: 'selectedCartVariationIndex',
						value: index 
					})
				}
			},
			cart() {
				return this.$store.state.cart
			},
			dynamicDiscount () {
				return this.$store.getters.dynamicDiscount
			},
			settings () {
				return this.$store.state.settings
			},
			selectedDiscount () {
				return this.$store.state.selectedDiscount
			}
		},
		methods: {
			prefill() {
				if (this.selectedDynamicDiscountType === 'item') {
					this.applyDiscountOnEachItem = this.settings.general.apply_discount_on_each_item

					if (this.selectedCartVariation.item_discount && this.selectedCartVariation.item_discount.length) {
						const appliedDynamicDiscount = this.cart.items[this.selectedCartVariationIndex].item_discount.find(i => i.id === this.dynamicDiscount.id)

						if (appliedDynamicDiscount) {
							this.selectedDiscountType = appliedDynamicDiscount.get_discount_type
							this.selectedDiscountValue = appliedDynamicDiscount.get_discount_value
							this.applyDiscountOnEachItem = appliedDynamicDiscount.apply_discount_on_each_item
						}
					}
				} else if (this.selectedDiscount && this.dynamicDiscount && this.selectedDiscount.id === this.dynamicDiscount.id) {
					this.selectedDiscountType = this.selectedDiscount.get_discount_type
					this.selectedDiscountValue = this.selectedDiscount.get_discount_value
					this.maximumDiscountValue = this.selectedDiscount.max_discount_value
				}
			},
			resetForm () {
				this.selectedDiscountType = null
				this.selectedDiscountValue = null
				this.maximumDiscountValue = null
				this.applyDiscountOnEachItem = null
			},
			applyDiscount() {
				this.$refs.validator.validate().then(success => {
					if (success) {

						if (this.selectedDynamicDiscountType === 'item') {
							const itemDiscount = this.cart.items[this.selectedCartVariationIndex].item_discount
							const itemIndex = itemDiscount.findIndex(i => i.id === this.dynamicDiscount.id)

							if (itemIndex !== -1) {
								itemDiscount.pop(itemIndex)
							}

							this.cart.items[this.selectedCartVariationIndex].item_discount.push({
								id: this.dynamicDiscount.id,
								name: this.dynamicDiscount.name,
								buy_batch_id: null,
								get_batch_id: null,
								buy_condition_type: 'quantity',
								buy_condition_value: 0,
								get_discount_type: this.selectedDiscountType,
								get_discount_value: +this.selectedDiscountValue,
								get_discount_quantity: this.applyDiscountOnEachItem ? this.cart.items[this.selectedCartVariationIndex].quantity : 1,
								get_discount_max_quantity: this.applyDiscountOnEachItem ? this.cart.items[this.selectedCartVariationIndex].quantity : 1,
								quantity: this.applyDiscountOnEachItem ? this.cart.items[this.selectedCartVariationIndex].quantity : 1,
								type: 'ITEM',
								entity: this.dynamicDiscount.entity,
								apply_discount_on_each_item: this.applyDiscountOnEachItem,
								updated_at: new Date()
							})
							this.$store.commit('setCart', this.cart)
						} else {
							this.$store.commit('setState', { key: 'selectedDiscount', value: {
								...this.dynamicDiscount,
								entity: 'all',
								buy_condition_type: 'price',
								buy_condition_value: this.cart.price.subtotal,
								get_discount_type: this.selectedDiscountType,
								get_discount_value: +this.selectedDiscountValue,
								max_discount_value: this.selectedDiscountType === 'price' ? +this.selectedDiscountValue : +this.maximumDiscountValue
							} })
						}

						this.$store.dispatch('cartCalculation')
						this.closeDialog()
					}
				})
			},
			calculateCartItemTotal (item, discountType) {
				const total = item.subtotal + (this.selectedDynamicDiscountType === 'item' ? item.taxAmount : item.tax)
				const maxDiscount = +((discountType === 'percentage')
					? 100
					: ((this.applyDiscountOnEachItem
						&& this.selectedDynamicDiscountType === 'item')
						? total / item.quantity
						: total).toFixed(2))


				return ((this.settings.general.round_off_total && !this.applyDiscountOnEachItem) ? Math.round(maxDiscount) : maxDiscount)
			},
			closeDialog() {
				this.selectedCartVariationIndex = null
				this.selectedCartVariation = null
				this.selectedDiscountType = null
				this.selectedDiscountValue = null
				this.showDynamicDiscount = false
			}
		}
	}
</script>

