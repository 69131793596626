import JsSHA from 'jssha'
import base32 from 'hi-base32'

const filterItem = (items, item, returnType) => {
    const itemModifiers = item.groups ? item.groups.filter(g => g.type === 'modifier')
        .map(m => m.group_item_variation_id) : []
    const index = items.findIndex((i) => {
        const iModifiers = i.groups ? i.groups.filter(g => g.type === 'modifier')
            .map(m => m.group_item_variation_id) : []

        return (i.id || i.variation_id) === (item.id || item.variation_id) && i.batch_id === (item.batch_id || null) &&
            i.price === item.price && iModifiers.filter(m => !itemModifiers.includes(m)).length === 0
    })

    if (returnType === 'index') {
        return index
    } else {
        return index !== -1 ? items[index] : null
    }
}

function hex2dec(s) {
    return parseInt(s, 16);
}

function dec2hex(s) {
    return (s < 15.5 ? '0' : '') + Math.round(s).toString(16);
}

function leftpad(str, len, pad) {
    if (len + 1 >= str.length) {
        str = Array(len + 1 - str.length).join(pad) + str;
    }
    return str;
}

function base32tohex(base32) {
    var base32chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ234567";
    var bits = "";
    var hex = "";

    for (var i = 0; i < base32.length; i++) {
        var val = base32chars.indexOf(base32.charAt(i).toUpperCase());
        bits += leftpad(val.toString(2), 5, '0');
    }

    for (var i = 0; i + 4 <= bits.length; i += 4) {
        var chunk = bits.substr(i, 4);
        hex = hex + parseInt(chunk, 2).toString(16);
    }
    return hex;

}

export default {
    reload() {
        window.location.reload()
    },
    getDataURL(url) {
        return new Promise(resolve => {
            const image = new Image()

            image.onload = function() {
                const canvas = document.createElement('canvas')

                canvas.width = this.naturalWidth
                canvas.height = this.naturalHeight
                canvas.getContext('2d').drawImage(this, 0, 0)
                resolve(canvas.toDataURL('image/png'))
            }

            image.src = `/api/image?r=${url}`
        })
    },
    getPriceFromBarcode(barcode) {
        if (barcode.length === 13) {
            return {
                barcode: barcode.substr(0, 7),
                price: parseFloat(barcode.substr(-6, 3) + "." + barcode.substr(-3, 2))
            }
        } else if (barcode.length === 12) {
            return {
                barcode: barcode.substr(0, 7),
                price: parseFloat(barcode.substr(-5, 2) + "." + barcode.substr(-3, 2))
            }
        }

        return null
    },
    getUpdatedAtDatetime(time) {
        if (!time)
            return null

        let day = new Date(time)

        day.setHours(day.getHours() - 1)

        return new Date(day.getTime() + day.getTimezoneOffset() * 60000)
            .toISOString().slice(0, 19).replace('T', ' ')
    },
    applyMultiPricing(variation, priceCategoryId) {
        const priceCategory = variation.price_category.find(priceCategory => {
            return priceCategory.category_id === priceCategoryId &&
                (priceCategory.config === 'local' || priceCategory.type === 'item')
        })

        if (priceCategory) {
            variation.tax = priceCategory.tax

            if (priceCategory.price_type === 'percentage') {
                variation.price = variation.original_price +
                    ((priceCategory.price_value / 100) * variation.original_price)
            } else if (priceCategory.price_type === 'fixed') {
                variation.price = variation.original_price + priceCategory.price_value
            }
            if (variation.price < 0)
                variation.price = 0

        } else {
            variation.price = variation.original_price
        }

        return variation
    },
	validateConditions (price, conditions) {
		for (const key in conditions) {
			// eslint-disable-next-line
			if (Object.prototype.hasOwnProperty.call(price, key) && !eval(conditions[key].map((condition) => {
				return `${price[key]} ${condition.operator} ${condition.value}`
			}).join(' && '))) {
				return false
			}
		}

		return true
	},
    groupBy(xs, key) {
        return xs.reduce((rv, x) => {
            (rv[x[key]] = rv[x[key]] || []).push(x)
            return rv
        }, {})
    },
    toFixed(num, fixed) {
        return num.toString().match(new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?'))[0]
    },
    generateReceiptCode() {
        const date = new Date()
        const lastOrderDay = +this.$bridge.getLocalStorage('lastOrderDay')
        let orderCount = +this.$bridge.getLocalStorage('orderCount')

        if (!orderCount || lastOrderDay !== date.getDate()) {
            orderCount = 1
        } else {
            orderCount += 1
        }

        return this.$moment().format('YYMMDD') + orderCount.toString().padStart(4, '0')
    },
	generateRefCode () {
		const date = this.$moment()
		const refCode = JSON.parse(this.$bridge.getLocalStorage('refCode') || '{}')
		const prefix = `${this.$store.state.device?.ref_code_prefix || this.$store.state.location.customAttributes?.order_seq_prefix || this.$store.state.locationId}${!this.$store.state.device?.ref_code_prefix ? this.$store.state.deviceId : ''}`
		let refCodeCount = +refCode.count || 0
		const time = this.$store.state.settings.general.order_sequential_reset_time || 'year'
		let increaseCount = time !== 'year' ? this.$moment(refCode.date).isSame(date, time) : true
		const resetMonth = this.$store.state.settings.general.order_sequential_reset_month || 1

		if (time === 'year' && (new Date(date.year() + '-' + resetMonth + '-' + 1) > new Date(refCode.date)) && +resetMonth === (date.month() + 1)) {
			increaseCount = false
		}

		if (refCode.date && increaseCount) {
			refCodeCount += 1
		} else {
			refCodeCount = 1
		}

		return {
			code: `${prefix}${refCodeCount.toString().padStart(this.$store.state.settings.general.invoice_counter || 4, '0')}`,
			prefix
		}
	},
    getUnitDecimalPlaces(unit) {
        let decimalPlaces = 0

        switch (unit) {
            case 'count':
                decimalPlaces = 0
                break
            case 'weight':
            case 'volume':
			case 'length':
                decimalPlaces = 3
                break
        }

        return decimalPlaces
    },
    showErrors(err) {
        if (this.$refs.validator && err.response && err.response.status === 422 &&
            Object.keys(err.response.data.data.errors).length) {
            const errors = {}

            for (const key in err.response.data.data.errors) {
                errors[key] = [err.response.data.data.errors[key]]
            }

            this.$refs.validator.setErrors(errors)
        } else {
            const errMsg = 'Oops! Something went wrong. Try again later'

            console.error(err) // eslint-disable-line
            this.$ons.notification.toast(err.response ? (err.response.data.message || errMsg) : errMsg, {
                timeout: 3000
            })
        }
    },
	evenly (numerator, denominator, fixed) {
		if (fixed === undefined) { fixed = 2 }

		const pow10 = Math.pow(10, fixed)
		numerator *= pow10

		const divide = Math.floor(numerator / denominator)
		const remain = numerator - divide * denominator
		const result = []; let cnt = 0

		while (++cnt && cnt <= denominator) {
			result.push((cnt <= remain ? divide + 1 : divide) / pow10)
		}

		return result
	},
    addOrderItemsToCart(order, newItems = []) {
        this.$store.commit('resetCart')

        const cartItems = []
		const itemDiscounts = this.$store.getters.discounts('item')
		const categoryDiscounts = this.$store.getters.discounts('category')
        let charges = []

        if (order.charges.length) {
			charges = this.$bridge.getCharges(
				this.deviceId,
				this.$store.state.locationId,
				JSON.stringify({ id: order.charges.map(c => c.id) })
			)

			charges = (typeof charges === 'string' ? JSON.parse(charges) : charges).data
		}

        if (order.price_category) {
            this.$store.commit('setState', {
                key: 'selectedPriceCategory',
                value: order.price_category
            })
        }

        this.$store.commit('setState', {
            key: 'selectedDiscount',
            value: order.discounts[0]
        })

        this.$store.commit('setState', {
            key: 'charges',
            value: charges
        })

        this.$store.commit('setState', {
            key: 'tip',
            value: order.custom_attributes.tip || null
        })

        order.items.forEach(item => {
			let priceCategory = null
            let dbItemVariation = this.$bridge.getItemVariations(this.deviceId, JSON.stringify({
                id: item.variation_id
            }))

			let itemVariationDiscounts = itemDiscounts.filter((d) => {
				return d.discount_items.findIndex((i) => {
					return i.variation_id === item.variation_id && i.buy_condition_value
				}) !== -1
			})
			const itemCategoryDiscounts = categoryDiscounts.filter((d) => {
				d.entity = 'category'
				return d.discount_categories.findIndex((i) => {
					return i.category_id === item.category_id && i.buy_condition_value
				}) !== -1
			})

			itemVariationDiscounts = itemVariationDiscounts.concat(itemCategoryDiscounts)

            dbItemVariation = (
                typeof dbItemVariation === 'string' ? JSON.parse(dbItemVariation) : dbItemVariation
            )[0]

            if (dbItemVariation) {
				if (order.price_category) {
					priceCategory = dbItemVariation.price_category.find(pc => pc.category_id === order.price_category.id)
				}

                cartItems.unshift({
                    id: item.variation_id,
                    item_id: item.item_id,
                    category_id: item.category_id,
                    inventory_id: item.inventory_id,
                    kot_device_id: dbItemVariation.kot_device_id,
                    name: item.variation_name,
                    item_name: item.item_name,
                    alternate_name: item.alternate_name,
                    sku: dbItemVariation.sku,
                    type: dbItemVariation.type,
                    barcode: dbItemVariation.barcode,
                    hsn: dbItemVariation.custom_attributes.hsn || '',
                    unit_measure_type: item.unit_measure_type,
                    batch_id: null,
                    mrp: parseFloat(dbItemVariation.custom_attributes.mrp || 0),
                    price: parseFloat(item.price),
                    quantity: item.quantity,
					tax: priceCategory
						? priceCategory.tax
						: (item.taxes.length
							? item.taxes.map((t) => {
								return {
									id: t.tax_id,
									name: t.tax_name,
									inclusion_type: item.tax_type === 'exclusive' ? 'additive' : 'inclusive',
									rate: t.tax_rate
								}
							})
							: dbItemVariation.tax),
                    discount: item.discounts,
                    item_discount: item.discounts.filter(d => d.hasOwnProperty('type')),
					combo_discount: itemVariationDiscounts.reduce((discounts, discount) => {
						discount.discount_items.forEach((di) => {
							if (!(di.variation_id === item.variation_id && di.get_discount_quantity === 0 &&
								di.get_discount_type)) {
								discounts.push({
									...di,
									type: 'COMBO',
									id: discount.id,
									name: discount.name,
									entity: discount.entity
								})
							}
						})

						if (discount.discount_categories) {
							discount.discount_categories.forEach((di) => {
								if (di.category_id === item.category_id) {
									discounts.push({
										...di,
										type: 'COMBO',
										id: discount.id,
										name: discount.name,
										entity: discount.entity
									})
								}
							})
						}

						return discounts
					}, []),
                    itemization_type: dbItemVariation.itemization_type || 'item',
                    groups: item.groups,
                    notes: item.notes,
                    taxes: item.taxes,
                    discountedAmount: item.discounted_amount,
                    discountedTax: item.discounted_tax,
                    discounts: item.discounts
                })
            }
        })

        this.$store.commit('setCart', { items: cartItems })

        if (newItems.length) {
            newItems.forEach(item => {
                const cartItemIndex = filterItem(cartItems, item, 'index')

                if (cartItemIndex !== -1)
                    item.quantity += cartItems[cartItemIndex].quantity

                if (item.item_discount.length) {
                    cartItems.unshift(item)
                    this.$store.commit('setCart', { items: cartItems })
                } else {
                    this.$store.dispatch('modifyCart', {
                        item: {
                            id: item.item_id,
                            category_id: item.category_id,
                            name: item.item_name
                        },
                        variation: {
                            id: item.id,
                            inventory_id: item.inventory_id,
                            kot_device_id: item.kot_device_id,
                            name: item.name,
                            sku: item.sku,
                            type: item.type,
                            unit_measure_type: item.unit_measure_type,
                            batch_id: null,
                            price: item.price,
                            quantity: item.quantity,
                            tax: item.tax,
                            itemization_type: item.itemization_type,
                            groups: item.groups,
                            notes: item.notes,
                            custom_attributes: {
                                alternate_language: item.alternate_name,
                                hsn: item.hsn,
                                mrp: item.mrp
                            }
                        },
                        triggerCalculation: false
                    })
                }
            })
        }

        this.$store.dispatch('cartCalculation')
    },
    verifyTOTP(merchantId, locationId, token) {
        let secret = base32.encode(Buffer.from(`posbytz|${merchantId}|${locationId}`, 'utf-8').toString('hex').padStart(32, '0'))
        secret = secret.split('=').join('')
        let epoch, time, shaObj, hmac, offset, otp;
        epoch = Math.round(Date.now() / 1000.0);
        time = leftpad(dec2hex(Math.floor(epoch / 30)), 16, '0');
        shaObj = new JsSHA('SHA-1', 'HEX');
        shaObj.setHMACKey(base32tohex(secret).slice(0, -1), 'HEX');
        shaObj.update(time);
        hmac = shaObj.getHMAC('HEX');
        offset = hex2dec(hmac.substring(hmac.length - 1));
        otp = (hex2dec(hmac.substr(offset * 2, 8)) & hex2dec('7fffffff')) + '';
        otp = (otp).substr(otp.length - 6, 6)
        if (process.NODE_ENV !== 'production') {
            console.log('otp', otp)
        }
        return otp === token
    },
    filterItem
}