export default app => {
    let isBackgroundSync = false

    const errorHandler = err => {
        if (err.response && [401, 410].includes(err.response.status)) {
            // if (err.response.status === 410) {
            // app.store.commit('setState', {
            // key: 'loginToken',
            // value: null,
            // save: false
            // })
            // }

            app.store.dispatch('logout')

            return true
        }

        return false
    }

    const getSyncOptions = (model, force = false) => {
        const deviceId = +app.$bridge.getLocalStorage('deviceId')
        const locationId = +app.$bridge.getLocalStorage('locationId')
        let options = {}
        let updatedAt = null
        switch (model) {
            case 'merchantDetails':
                options = {
                    model: 'merchant-details',
                    deviceId: deviceId,
                    locationId: locationId,
                    params: {
                        location_id: locationId,
						device_id: deviceId
                    }
                }
                break

            case 'employees':
                if (!force) {
                    updatedAt = app.$bridge.getLastUpdatedAt('Employee', deviceId)
                    updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
                }
                options = {
                    model: 'employees',
                    collection: 'Employee',
                    deviceId: deviceId,
                    locationId: locationId,
                    params: {
                        all_merchant: true,
                        location_id: locationId,
                        updated_at: app.getUpdatedAtDatetime(
                            updatedAt ? updatedAt.updated_at : null
                        ),
                        trash: true
                    }
                }

                break
            case 'devices':
                if (!force) {
                    updatedAt = app.$bridge.getLastUpdatedAt('Device', deviceId)
                    updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
                }
                options = {
                    model: 'devices',
                    collection: 'Device',
                    deviceId: deviceId,
                    locationId: locationId,
                    params: {
                        all_merchant: true,
                        location_id: locationId,
                        updated_at: app.getUpdatedAtDatetime(
                            updatedAt ? updatedAt.updated_at : null
                        ),
                        trash: true
                    }
                }

                break
            case 'floors':
                if (!force) {
                    updatedAt = app.$bridge.getLastUpdatedAt('Floor', deviceId)
                    updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
                }
                options = {
                    model: 'floors',
                    collection: 'Floor',
                    deviceId: deviceId,
                    locationId: locationId,
                    params: {
                        all_merchant: true,
                        location_id: locationId,
                        updated_at: app.getUpdatedAtDatetime(
                            updatedAt ? updatedAt.updated_at : null
                        ),
                        trash: true
                    }
                }

                break
            case 'floorTables':
                if (!force) {
                    updatedAt = app.$bridge.getLastUpdatedAt('Table', deviceId)
                    updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
                }
                options = {
                    model: 'floorTables',
                    collection: 'Table',
                    deviceId: deviceId,
                    locationId: locationId,
                    params: {
                        all_merchant: true,
                        location_id: locationId,
                        updated_at: app.getUpdatedAtDatetime(
                            updatedAt ? updatedAt.updated_at : null
                        ),
                        trash: true
                    }
                }

                break
            case 'merchantCustomers':
                if (!force) {
                    updatedAt = app.$bridge.getLastUpdatedAt('Customer', deviceId)
                    updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
                }
                options = {
                    model: 'merchantCustomers',
                    collection: 'Customer',
                    deviceId: deviceId,
                    locationId: locationId,
                    params: {
                        all_merchant: true,
                        updated_at: app.getUpdatedAtDatetime(
                            updatedAt ? updatedAt.updated_at : null
                        ),
                        trash: true
                    }
                }

                break
            case 'merchantCustomerGroups':
				if (!force) {
					updatedAt = app.$bridge.getLastUpdatedAt('CustomerGroup', deviceId)
					updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
				}

                options = {
                    model,
                    collection: 'CustomerGroup',
                    name: app.i18n.tc('customer groups', 2),
                    deviceId,
                    locationId,
                    params: {
                        all_merchant: true,
                        updated_at: app.getUpdatedAtDatetime(updatedAt ? updatedAt.updated_at : null),
                        trash: true
                    }
                }

                break
            case 'categories':
                if (!force) {
                    updatedAt = app.$bridge.getLastUpdatedAt('Category', deviceId)
                    updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
                }
                options = {
                    model: 'categories',
                    collection: 'Category',
                    deviceId: deviceId,
                    locationId: locationId,
                    params: {
                        all_merchant: true,
                        location_id: locationId,
                        updated_at: app.getUpdatedAtDatetime(
                            updatedAt ? updatedAt.updated_at : null
                        ),
                        trash: true
                    }
                }

                break
            case 'taxes':
                if (!force) {
                    updatedAt = app.$bridge.getLastUpdatedAt('Tax', deviceId)
                    updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
                }
                options = {
                    model: 'taxes',
                    collection: 'Tax',
                    deviceId: deviceId,
                    locationId: locationId,
                    params: {
                        all_merchant: true,
                        updated_at: app.getUpdatedAtDatetime(
                            updatedAt ? updatedAt.updated_at : null
                        ),
                        trash: true
                    }
                }

                break
            case 'discounts':
                if (!force) {
                    updatedAt = app.$bridge.getLastUpdatedAt('Discount', deviceId)
                    updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
                }
                options = {
                    model: 'discounts',
                    collection: 'Discount',
                    deviceId: deviceId,
                    locationId: locationId,
                    params: {
                        all_merchant: true,
                        location_id: locationId,
                        updated_at: app.getUpdatedAtDatetime(
                            updatedAt ? updatedAt.updated_at : null
                        ),
                        trash: true
                    }
                }

                break
            case 'merchantCharges':
                if (!force) {
                    updatedAt = typeof app.$bridge.getCharges === 'function' ? app.$bridge.getLastUpdatedAt('Charge', deviceId) : null
                    updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
                }

                options = {
                    model: 'merchantCharges',
                    collection: 'Charge',
                    deviceId: deviceId,
                    locationId: locationId,
                    params: {
                        all_merchant: true,
                        location_id: locationId,
                        updated_at: app.getUpdatedAtDatetime(
                            updatedAt ? updatedAt.updated_at : null
                        ),
                        trash: true
                    }
                }

                break
            case 'merchantPriceCategories':
                if (!force) {
                    updatedAt = app.$bridge.getLastUpdatedAt('PriceCategory', deviceId)
                    updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
                }
                options = {
                    model: 'merchantPriceCategories',
                    collection: 'PriceCategory',
                    deviceId: deviceId,
                    locationId: locationId,
                    params: {
                        all_merchant: true,
                        updated_at: app.getUpdatedAtDatetime(
                            updatedAt ? updatedAt.updated_at : null
                        ),
                        trash: true
                    }
                }

                break
            case 'items':
                if (!force) {
                    updatedAt = app.$bridge.getLastUpdatedAt('Item', deviceId)
                    updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
                }
                options = {
                    model: 'items',
                    collection: 'Item',
                    deviceId: deviceId,
                    locationId: locationId,
                    params: {
                        all_merchant: true,
                        location_id: locationId,
                        updated_at: app.getUpdatedAtDatetime(
                            updatedAt ? updatedAt.updated_at : null
                        ),
                        trash: true
                    }
                }

                break
            case 'itemVariationGroups':
                if (!force) {
                    updatedAt = app.$bridge.getLastUpdatedAt('ItemVariationGroup', deviceId)
                    updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
                }
                options = {
                    model: 'itemVariationGroups',
                    collection: 'ItemVariationGroup',
                    deviceId: deviceId,
                    locationId: locationId,
                    params: {
                        all_merchant: true,
                        location_id: locationId,
                        updated_at: app.getUpdatedAtDatetime(updatedAt ? updatedAt.updated_at : null),
                        trash: true
                    }
                }

                break
            case 'merchantPaymentMethods':
                if (!force) {
                    updatedAt = app.$bridge.getLastUpdatedAt('PaymentMethod', deviceId)
                    updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
                }
                options = {
                    model: 'merchantPaymentMethods',
                    collection: 'PaymentMethod',
                    deviceId: deviceId,
                    locationId: locationId,
                    params: {
                        all_merchant: true,
                        updated_at: app.getUpdatedAtDatetime(
                            updatedAt ? updatedAt.updated_at : null
                        ),
                        trash: true
                    }
                }
                break
            case 'settings':
                options = {
                    model: 'settings',
                    deviceId: deviceId,
                    locationId: locationId,
                }
                break

            default:
                break
        }

        return options
    }

    const sync = ({ model, collection, deviceId, locationId, params, silent, page = 1 }) => {
        if (app.$offline.state === 'up') {
            if (!silent && page === 1 && model !== 'merchant-details') {
                app.store.commit('setSyncModels', {
                    ...app.store.state.syncModels,
                    [model]: {
                        ...app.store.state.syncModels[model],
                        percent: 0
                    }
                })
            }

            return new Promise((resolve, reject) => {
                app.$axios.get(`/api/pos/v1/sync/${model}`, {
                    params: Object.assign({}, {
                        page: page,
                        limit: 500
                    }, params)
                }).then(response => {
                    if (collection) {
                        app.$bridge.customInsertOrUpdate(
                            collection, deviceId, locationId, app.$bridge.getName() === 'ANDROID' ?
                            JSON.stringify(response.data.data[model]) : response.data.data[model]
                        )

						if (model === 'employees') {
                            let employees = response.data.data[model]

							employees = typeof employees === 'string' ? JSON.parse(employees) : employees

							app.store.commit('setState', {
								key: 'employees',
								value: employees
							})

							if (app.store.state.employee) {
								app.store.commit('setState', {
									key: 'employee',
									value: {
										...app.store.state.employee,
										...employees.find(e => e.id === app.store.state.employee.id)
									},
									save: true
								})
							}
						}

                        if (!silent && page < response.data.data.pagination.lastPage) {
                            app.store.commit('setSyncModels', {
                                ...app.store.state.syncModels,
                                [model]: {
                                    ...app.store.state.syncModels[model],
                                    percent: Math.floor((page / response.data.data.pagination.lastPage) * 100)
                                }
                            })

                            return sync({
                                model: model,
                                collection: collection,
                                deviceId: deviceId,
                                params: params,
                                page: ++page
                            }).then(() => resolve())
                        } else if (!silent) {
                            app.store.commit('setSyncModels', {
                                ...app.store.state.syncModels,
                                [model]: {
                                    ...app.store.state.syncModels[model],
                                    percent: 100
                                }
                            })
                            setTimeout(resolve, 1000)
                        }
                    } else if (model === 'settings') {
                        app.store.commit('setSettings', response.data.data)
                        app.$bridge.setLocalStorage('settings', JSON.stringify(response.data.data))
                        if (!silent) {
                            app.store.commit('setSyncModels', {
                                ...app.store.state.syncModels,
                                [model]: {
                                    ...app.store.state.syncModels[model],
                                    percent: 100
                                }
                            })
                        }
                        setTimeout(resolve, 1000)
                    } else if (model === 'merchant-details') {
                        let merchantLogos = []

                        if (response.data.data.merchant.id) {
                            merchantLogos.push({
                                id: response.data.data.merchant.id,
                                logoUrl: response.data.data.merchant.logoUrl,
							    letterHeadUrl: response.data.data.merchant.customAttributes.letter_head_img
                            })
                        }

                        if (response.data.data.merchant.childMerchants && response.data.data.merchant.childMerchants.length) {
                            merchantLogos = merchantLogos.concat(response.data.data.merchant.childMerchants.reduce((arr, m) => {
                                if (m.logoUrl) {
                                    arr.push({
                                        id: m.id,
                                        logoUrl: m.logoUrl
                                    })
                                }

                                return arr
                            }, []))
                        }

                        for (let i = 0; i < merchantLogos.length; i++) {
                            if (merchantLogos[i].logoUrl) {
								app.getDataURL(merchantLogos[i].logoUrl)
									.then(dataURL => app.$bridge.setLocalStorage(`merchant${merchantLogos[i].id}Logo`, dataURL))
									.catch(console.error) // eslint-disable-line
							} else {
								app.$bridge.removeLocalStorage(`merchant${merchantLogos[i].id}Logo`)
							}

							if (merchantLogos[i].letterHeadUrl) {
								app.getDataURL(merchantLogos[i].letterHeadUrl)
									.then(dataURL => app.$bridge.setLocalStorage(`merchant${merchantLogos[i].id}LetterHeadImage`, dataURL))
									.catch(console.error)
							} else {
								app.$bridge.removeLocalStorage(`merchant${merchantLogos[i].id}LetterHeadImage`)
							}
                        }

                        app.store.commit('setSettings', response.data.data.merchant.settings)
                        delete response.data.data.merchant.settings
                        app.store.commit('setState', {
                            key: 'merchant',
                            value: response.data.data.merchant,
                            save: true
                        })
                        app.store.dispatch('checkSubscription')
                        app.store.commit('setState', {
                            key: 'location',
                            value: response.data.data.location,
                            save: true
                        })
						app.store.commit('setState', {
							key: 'device',
							value: response.data.data.device,
							save: true
						})
                        if (!silent) {
                            model = 'merchantDetails'
                            app.store.commit('setSyncModels', {
                                ...app.store.state.syncModels,
                                [model]: {
                                    ...app.store.state.syncModels[model],
                                    percent: 100
                                }
                            })
                        }

                        setTimeout(resolve, 1000)
                    }

                }).catch(err => {
                    console.error(err)

                    if (errorHandler(err))
                        return false

                    if (!silent) {
                        app.store.commit('setSyncModels', {
                            ...app.store.state.syncModels,
                            [model]: {
                                ...app.store.state.syncModels[model],
                                percent: 100
                            }
                        })
                    }

                    reject()
                })
            })
        } else {
            this.$ons.notification.toast('Please connect to internet and try again.', {
                timeout: 5000
            })

            return Promise.resolve()
        }
    }

    const syncAll = (forceFlag = false) => {
        return sync(getSyncOptions('merchantDetails', forceFlag))
            .then(() => sync(getSyncOptions('employees', forceFlag)))
            .then(() => {
                if (!['restaurant', 'qsr'].includes(app.store.state.merchant.businessType))
                    return Promise.resolve()

                return sync(getSyncOptions('devices', forceFlag))
            }).then(() => {
                if (app.store.state.merchant.businessType !== 'restaurant')
                    return Promise.resolve()

                return sync(getSyncOptions('floors', forceFlag))
            }).then(() => {
                if (app.store.state.merchant.businessType !== 'restaurant')
                    return Promise.resolve()

                return sync(getSyncOptions('floorTables', forceFlag))
            })
            .then(() => sync(getSyncOptions('merchantCustomerGroups', forceFlag)))
            .then(() => sync(getSyncOptions('merchantCustomers', forceFlag)))
            .then(() => sync(getSyncOptions('categories', forceFlag)))
            .then(() => sync(getSyncOptions('taxes', forceFlag)))
            .then(() => sync(getSyncOptions('discounts', forceFlag)))
            .then(() => sync(getSyncOptions('merchantCharges', forceFlag)))
            .then(() => sync(getSyncOptions('merchantPriceCategories', forceFlag)))
            .then(() => sync(getSyncOptions('items', forceFlag)))
            .then(() => sync(getSyncOptions('itemVariationGroups', forceFlag)))
            .then(() => sync(getSyncOptions('merchantPaymentMethods', forceFlag)))
            .catch(err => console.error(err))
    }

    const preSyncProcess = syncData => {
        const deviceId = +app.$bridge.getLocalStorage('deviceId')

        return new Promise((resolve) => {
            switch (syncData.model_name) {
                case 'order':
                    {
                        let order = app.$bridge.getOrders(deviceId, JSON.stringify({
                            id: syncData.payload.orders[0].id
                        }))

                        order = (typeof order === 'string' ? JSON.parse(order) : order).data[0]

                        if (order) {
                            if (order.order_id) {
                                syncData.payload.orders[0].order_id = order.order_id
                            }

                            syncData.payload.orders[0].created_at = app.$moment.utc(order.created_at).format('YYYY-MM-DD HH:mm:ss')
                        }

                        resolve(syncData)
                        break
                    }
                case 'cash-drawer-shift-event':
                    let cashDrawerShift = app.$bridge.getCashDrawerShifts(deviceId, JSON.stringify({
                        id: syncData.payload.cash_drawer_shift_id
                    }))

                    cashDrawerShift = (typeof cashDrawerShift === 'string' ?
                        JSON.parse(cashDrawerShift) : cashDrawerShift).data[0]

                    if (cashDrawerShift)
                        syncData.payload.cash_drawer_shift_id = cashDrawerShift.cash_drawer_shift_id

                    resolve(syncData)
                    break
                case 'order-refund':
                    let order = app.$bridge.getOrders(deviceId, JSON.stringify({
                        id: syncData.payload.order_id
                    }))

                    order = (typeof order === 'string' ? JSON.parse(order) : order).data[0]

                    if (order)
                        syncData.payload.order_id = order.order_id

                    resolve(syncData)
                    break
                case 'customer-credit':
                    if (syncData.payload.customer_id) {
                        let customer = app.$bridge.getCustomers(deviceId, JSON.stringify({
                            id: syncData.payload.customer_id
                        }))

                        customer = (typeof customer === 'string' ? JSON.parse(customer) : customer)
                            .data[0]

                        if (customer) {
                            syncData.payload.merchant_customer_id = customer.customer_id
                        }
                    }

                    resolve(syncData)
                    break
                default:
                    resolve(syncData)
                    break
            }
        })
    }

    const postSyncProcess = (modelId, modelName, result) => {
        const date = new Date()
        const deviceId = +app.$bridge.getLocalStorage('deviceId')

        return new Promise((resolve) => {
            switch (modelName) {
                case 'employee-shift':
                    let employeeShift = app.$bridge.getEmployeeShifts(deviceId, JSON.stringify({
                        id: modelId
                    }))

                    employeeShift = typeof employeeShift === 'string' ?
                        JSON.parse(employeeShift) : employeeShift
                    employeeShift = employeeShift ? employeeShift[0] : null

                    if (employeeShift) {
                        employeeShift = Object.assign({}, employeeShift, {
                            updated_at: date,
                            is_synced: !!employeeShift.clock_out
                        })
                        app.$bridge.insert('EmployeeShift', app.$bridge.getName() === 'ANDROID' ?
                            JSON.stringify(employeeShift) : employeeShift, true
                        )
                    }

                    resolve()
                    break
                case 'cash-drawer-shift':
                    let cashDrawerShift = app.$bridge.getCashDrawerShifts(deviceId, JSON.stringify({
                        id: modelId
                    }))

                    cashDrawerShift = (typeof cashDrawerShift === 'string' ?
                        JSON.parse(cashDrawerShift) : cashDrawerShift).data[0]

                    if (cashDrawerShift) {
                        cashDrawerShift = {
                            id: cashDrawerShift.id,
                            cash_drawer_shift_id: result.cashDrawerShift.id,
                            updated_at: date,
                            is_synced: !!cashDrawerShift.closed_at
                        }
                        app.$bridge.insert('CashDrawerShift', app.$bridge.getName() === 'ANDROID' ?
                            JSON.stringify(cashDrawerShift) : cashDrawerShift, true)
                    }

                    resolve()
                    break
                case 'order':
                    let order = app.$bridge.getOrders(deviceId, JSON.stringify({ id: modelId }))
                    order = (typeof order === 'string' ? JSON.parse(order) : order).data[0]

                    if (order) {
                        order = {
                            id: order.id,
                            order_id: result.order.id,
                            updated_at: date,
                            is_synced: true
                        }
                        app.$bridge.insert('Order', app.$bridge.getName() === 'ANDROID' ?
                            JSON.stringify(order) : order, true)

                        if (result.order.customers.length) {
                            let customer = app.$bridge.getCustomers(deviceId, JSON.stringify({
                                id: parseInt(result.order.customers[0].customer.phone)
                            }))

                            customer = (typeof customer === 'string' ? JSON.parse(customer) : customer).data[0]

                            if (customer && !customer.customer_id) {
                                customer = {
                                    id: customer.id,
                                    customer_id: result.order.customers[0].customer.id,
                                    updated_at: date
                                }
                                app.$bridge.insert('Customer', app.$bridge.getName() === 'ANDROID' ?
                                    JSON.stringify(customer) : customer, true)
                            }
                        }
                    }

                    resolve()
                    break
                case 'cash-drawer-shift-event':
                    let cashDrawerShiftEvent = app.store.getters.appVersionNumber < 168 ? app.$bridge.getRecord(
                        'CashDrawerShiftEvent', deviceId, JSON.stringify([
                            { key: 'id', value: modelId }
                        ])
                    ) : app.$bridge.getRecord(
                        'CashDrawerShiftEvent', JSON.stringify([
                            { key: 'id', value: modelId }
                        ])
                    )

                    cashDrawerShiftEvent = typeof cashDrawerShiftEvent === 'string' ?
                        JSON.parse(cashDrawerShiftEvent) : cashDrawerShiftEvent

                    if (cashDrawerShiftEvent) {
                        cashDrawerShiftEvent = {
                            id: cashDrawerShiftEvent.id,
                            updated_at: date,
                            is_synced: true
                        }
                        app.$bridge
                            .insert('CashDrawerShiftEvent', app.$bridge.getName() === 'ANDROID' ?
                                JSON.stringify(cashDrawerShiftEvent) : cashDrawerShiftEvent, true)
                    }

                    resolve()
                    break
                case 'customer-credit':
                    let credit = app.store.getters.appVersionNumber < 168 ? app.$bridge.getRecord(
                        'Credit', deviceId, JSON.stringify([
                            { key: 'id', value: modelId }
                        ])
                    ) : app.$bridge.getRecord(
                        'Credit', JSON.stringify([
                            { key: 'id', value: modelId }
                        ])
                    )

                    credit = typeof credit === 'string' ? JSON.parse(credit) : credit

                    if (credit) {
                        credit = {
                            id: credit.id,
                            updated_at: date,
                            is_synced: true
                        }
                        app.$bridge.insert('Credit', app.$bridge.getName() === 'ANDROID' ?
                            JSON.stringify(credit) : credit, true)
                    }

                    resolve()
                    break
                case 'order-refund':
                    let refund = app.store.getters.appVersionNumber < 168 ? app.$bridge.getRecord(
                        'OrderRefund', deviceId, JSON.stringify([
                            { key: 'id', value: modelId }
                        ])
                    ) : app.$bridge.getRecord(
                        'OrderRefund', JSON.stringify([
                            { key: 'id', value: modelId }
                        ])
                    )

                    refund = typeof refund === 'string' ? JSON.parse(refund) : refund

                    if (refund) {
                        refund = {
                            id: refund.id,
                            updated_at: date,
                            is_synced: true
                        }
                        app.$bridge.insert('OrderRefund', app.$bridge.getName() === 'ANDROID' ?
                            JSON.stringify(refund) : refund, true)
                    }

                    resolve()
                    break
                default:
                    resolve()
                    break
            }
        })
    }

    const backgroundSync = () => {
        setInterval(() => {
            if (app.$offline.state === 'up' && !isBackgroundSync && app.store.state.isLoggedIn) {
                let syncData = app.$bridge.getSyncData()

                syncData = typeof syncData === 'string' ? JSON.parse(syncData) : syncData

				if (syncData) {
	                if (syncData.payload.data === 'down_sync') {
                        isBackgroundSync = true
	                    sync(getSyncOptions(syncData.model_name)).catch(err => {
                            console.error('down_sync error', err)
	                    }).finally(() => {
							app.$bridge.deleteRecord('Sync', syncData.id)
							isBackgroundSync = false
                        })
	                } else {
	                    if (syncData.attempts < 3) {
	                        isBackgroundSync = true
	                        preSyncProcess(syncData).then(syncData => {
	                            return app.$axios.post(`/api/pos/v1/sync/${syncData.model_name}`, syncData.payload)
	                        }).then(response => {
	                            return postSyncProcess(
	                                syncData.model_id, syncData.model_name, response.data.data
	                            )
	                        }).then(() => {
	                            app.$bridge.deleteRecord('Sync', syncData.id)
	                            isBackgroundSync = false
	                        }).catch(err => {
	                            if (err.response) {
	                                if (errorHandler(err))
	                                    return false

	                                const data = {
	                                    id: syncData.id,
	                                    attempts: syncData.attempts + 1
	                                }

	                                app.$bridge.insert('Sync', app.$bridge.getName() === 'ANDROID' ?
	                                    JSON.stringify(data) : data, true)
	                            }

	                            isBackgroundSync = false
	                        })
	                    } else {
	                        isBackgroundSync = true
	                        app.$axios.post('/api/pos/v1/sync/failed', {
	                            model_id: syncData.model_id,
	                            model_name: syncData.model_name,
	                            payload: syncData.payload
	                        }).then(() => {
	                            app.$bridge.deleteRecord('Sync', syncData.id)
	                            isBackgroundSync = false
	                        }).catch(err => {
	                            if (errorHandler(err))
	                                return false

	                            isBackgroundSync = false
	                        })
	                    }
	                }
				}

            }
        }, 3000)
    }

    return {
        getSyncOptions,
        sync,
        syncAll,
        backgroundSync
    }
}