import { extend, configure, setInteractionMode } from 'vee-validate';
import { alpha_spaces, required, email, is, min, max, length, numeric, digits, alpha_num, min_value, max_value, integer, regex } from 'vee-validate/dist/rules';
import libphonenumber from 'google-libphonenumber';

export default ({ app }) => {
  configure({
    defaultMessage: (field, values) => {
      return app.i18n.t(`validation.${values._rule_}`, values)
    }
  })
  setInteractionMode('eager')
  extend('alpha_spaces', alpha_spaces)
  extend('required', required)
  extend('email', email)
  extend('is', is)
  extend('min', min)
  extend('max', max)
  extend('numeric', numeric)
  extend('digits', digits)
  extend('alpha_num', alpha_num)
  extend('min_value', min_value)
  extend('max_value', max_value)
  extend('length', length)
  extend('integer', integer)
  extend('regex', regex)
  extend('password', {
    validate: (value, { other }) => value === other,
    message: 'The password confirmation does not match.',
    params: [{ name: 'other', isTarget: true }]
  })
  extend('decimal', {
    validate: (value, { count }) => {
      value = value.toString()

      if (value.includes('.')) {
        return value.split('.')[1].length <= count
      }

      return true
    },
    params: ['count']
  })
  extend('mobile', {
    validate: (value, { code }) => {
      if (value.length < 4) {
        return false
      } else {
        const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()

        return phoneUtil.isValidNumberForRegion(phoneUtil.parseAndKeepRawInput('' + phoneUtil.getCountryCodeForRegion(code) + value, code), code)
      }
    },
    params: ['code']
  })
  extend('greaterThanZero', {
    validate: (value) => {
      return value > 0
    }
  })
  extend('uniqueTaxType', {
    validate: value => value.map(t => t.inclusion_type).every((t, i, arr) => t === arr[0])
  })
}