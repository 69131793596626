export default ({ app }) => {
    const choice = choice => choice > 0 ? choice - 1 : 0

    app.i18n.pluralizationRules = {
        en: choice,
        'en-US': choice,
        'en-IN': choice,
        ar: choice,
        fr: choice
    }
}