<template>
	<div>
		<no-ssr>
			<nuxt />
		</no-ssr>
	</div>
</template>

<script>
	export default {
		computed: {
			isLoggedIn() {
				return this.$store.state.isLoggedIn
			},
			merchant() {
				return this.$store.state.merchant
			},
		},
		beforeMount() {
			this.$ons.platform.select('android')
			
			this.backgroundSync()
			if (this.merchant) {
				this.$socket.on('dirty-entity:' + this.merchant.id, model => {
					const options = this.getSyncOptions(model)
					if (this.isLoggedIn && Object.keys(options).length) {
						options.silent = true
						this.sync(options).then(() => {
							this.key++
						})
					}
				})
			}
		}
	}
</script>
