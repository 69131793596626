<template>
	<v-ons-action-sheet
		v-if="$bridge.getCharges"
		modifier="rowfooter"
		:visible.sync="showCharges"
		:title="$tc('charge', 2)"
		cancelable
		@posthide="applyCharges"
	>
		<v-ons-list
			v-if="applicableCharges.length > 0"
			class="overflow-auto pb-5"
			style="max-height: 250px"
		>
			<v-ons-list-item
				v-for="(charge, index) in applicableCharges"
				:key="index"
				tappable
			>
				<label class="left">
					<v-ons-checkbox
						v-model="charge.selected"
						:input-id="'charge-' + index"
						@click="charge.selected = !charge.selected"
					/>
				</label>
				<label
					class="center text-capitalize"
					:for="'charge-' + index"
				>
					{{ charge.name }}
				</label>
			</v-ons-list-item>
		</v-ons-list>
		<v-ons-row
			v-else
			class="h-100 text-muted bg-white pb-5"
		>
			<v-ons-col
				class="text-center"
				vertical-align="center"
			>
				<h3 class="text-capitalize">
					{{ $t('emptyState.charges') }}
				</h3>
			</v-ons-col>
		</v-ons-row>
		<v-ons-bottom-toolbar
			class="bg-white px-2"
			modifier="transparent"
		>
			<v-ons-button
				modifier="large"
				@click="applyCharges"
			>
				{{ $t('done') }}
			</v-ons-button>
		</v-ons-bottom-toolbar>
	</v-ons-action-sheet>
</template>
<script>
	export default {
		data () {
			return {
				applicableCharges: []
			}
		},
		computed: {
			deviceId () {
				return this.$store.state.deviceId
			},
			locationId () {
				return this.$store.state.locationId
			},
			showCharges: {
				get() {
					return this.$store.state.showCharges
				},
				set(value) {
					this.$store.commit('setState', {
						key: 'showCharges',
						value 
					})
				}
			},
			cart () {
				return this.$store.state.cart
			},
			charges: {
				get () {
					return this.$store.state.charges
				},
				set (value) {
					this.$store.commit('setState', { key: 'charges', value })
				}
			}
		},
		beforeMount () {
			this.getCharges()
		},
		methods: {
			getCharges () {
				let charges = this.$bridge.getCharges(this.deviceId, this.locationId, '')

				this.applicableCharges = (
					typeof charges === 'string' ? JSON.parse(charges) : charges
				).data.reduce((charges, charge) => {
					if (this.validateConditions(this.cart.price, charge.conditions)) {
						charge.selected = this.charges.findIndex(c => c.id === charge.id) > -1
						charges.push(charge)
					}

					return charges
				}, [])
			},
			applyCharges () {
				this.charges = [...this.applicableCharges.filter(c => c.selected)]
				this.$store.commit('setCart', { charges: this.charges })
				this.$store.dispatch('cartCalculation')
				this.showCharges = false
			}
		}
	}
</script>